<script lang="ts">
export interface IDropdownItem<T> {
  value: T;
  key: string;
  text: string;
}
export function stringToDropdownItems(
  values: string[] | readonly string[] | null | undefined,
  includeEmpty = false,
  emptyValue = '',
): IDropdownItem<string>[] {
  return toDropdownItems(values, (i) => [i, i], includeEmpty, emptyValue);
}
export function toDropdownItems<V, T = string>(
  values: V[] | readonly V[] | null | undefined,
  get: (item: V) => [key: string, text: string, value?: T],
  includeEmpty = false,
  emptyValue: T = '' as T,
  emptyLabel = '',
): IDropdownItem<T>[] {
  return [
    ...(includeEmpty ? [{ value: emptyValue, key: '', text: emptyLabel }] : []),
    ...(values?.map((c): IDropdownItem<T> => {
      const [key, text, value] = get(c);
      return { key, text, value: value ?? (key as T) };
    }) ?? []),
  ];
}
</script>
<script
  setup
  lang="ts"
  generic="
    T extends string | number | boolean | object | undefined | null | symbol
  "
>
import { useI18n } from 'vue-i18n';
import { computed, ref, toRaw, watch, watchEffect } from 'vue';
import InputLabel, { type IInputLabelProps } from './InputLabel.vue';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import MaterialIcon from '../MaterialIcon.vue';

const props = withDefaults(
  defineProps<
    IInputLabelProps & {
      modelValue: T | T[] | undefined;
      options:
        | IDropdownItem<T>[]
        | {
            values: T[];
            getOption: (item: T) => [key: string, text: string, value?: T];
          };
      multiple?: { emptyLabel: string; maxItems?: number };
      getkey: (val?: T | null) => number | string | null | undefined;
      buttonclass?: string | string[];
      disabled?: boolean;
      allowEmptyValue?: boolean;
      disableEmptyOption?: boolean;
      changeUndefinedToNull?: boolean;
      noChrome?: boolean;
    }
  >(),
  {
    label: undefined,
    multiple: undefined,
    buttonclass: () => [],
    placeholder: undefined,
    disabled: false,
    required: false,
    allowEmptyValue: false,
    disableEmptyOption: false,
  },
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
  (e: 'update:valid', valid: boolean): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>();
defineSlots<{
  option(props: { item: IDropdownItem<T> }): unknown;
  button(props: { selectedItems: (T | null | undefined)[] }): unknown;
}>();
const { t } = useI18n();

const _options = computed(() =>
  Array.isArray(props.options)
    ? props.options
    : toDropdownItems(props.options.values, props.options.getOption),
);

const getValue = (key: string | undefined): T | undefined => {
  return toRaw(getOption(key)?.value);
};
const _getKey = (val: T | null | undefined): string => {
  const key = props.getkey(val);
  if (!key) return '';
  else return key.toString();
};
const getOption = (key: string | number | undefined) => {
  if (!key) return undefined;
  return _options.value.find((i) => i.key == key);
};
const onSelectChange = (val: HTMLCollectionOf<HTMLOptionElement>) => {
  emit('update:modelValue', getValue(val[0]?.value));
};
const onListboxChange = (val: string | string[] | undefined) => {
  if (Array.isArray(val)) {
    const results = val
      .map((v) => getValue(v))
      .filter((r) => r !== undefined) as T[];
    emit('update:modelValue', results);
  } else {
    const result = getValue(val);
    if (Array.isArray(props.modelValue)) {
      emit('update:modelValue', result ? [result] : []);
    } else {
      emit('update:modelValue', result);
    }
  }
};
const arrSelectedItems = computed((): (T | null | undefined)[] => {
  if (!props.modelValue && !props.allowEmptyValue) {
    return [];
  } else if (Array.isArray(props.modelValue)) {
    return props.modelValue;
  } else if (props.changeUndefinedToNull && props.modelValue === undefined) {
    return [null];
  } else {
    return [props.modelValue];
  }
});
const arrSelectedKeys = computed(() =>
  arrSelectedItems.value.map((i) => _getKey(i)),
);
const canSelectArray = computed(
  () => !!props.multiple && props.multiple?.maxItems !== 1,
);
const isSelected = (opt: IDropdownItem<T>): boolean => {
  const optKey = _getKey(opt.value);
  return (
    arrSelectedItems.value?.find((i) => _getKey(i) == optKey) !== undefined
  );
};
watch(
  () => props.modelValue,
  () => {
    emit(
      'update:valid',
      !props.required ||
        !(
          (Array.isArray(props.modelValue) && props.modelValue.length == 0) ||
          !props.modelValue
        ),
    );
  },
);
const listboxButtonRef = ref<InstanceType<typeof ListboxButton>>();
const attrObserver = ref<MutationObserver>();
const isOpen = ref(false);
watchEffect(() => {
  if (!listboxButtonRef.value) return;
  if (attrObserver.value) {
    attrObserver.value.disconnect();
  }
  attrObserver.value = new MutationObserver(function (records) {
    isOpen.value = !!(
      records[0]?.target as HTMLElement
    )?.dataset.headlessuiState?.includes('open');
  });
  attrObserver.value.observe(listboxButtonRef.value.$el, {
    attributes: true,
    attributeFilter: ['data-headlessui-state'],
    childList: false,
    characterData: false,
  });
});
watch(isOpen, () => {
  if (isOpen.value) emit('focus');
  else emit('blur');
});
</script>
<template>
  <div>
    <label :for="forName" class="flex flex-col whitespace-nowrap">
      <InputLabel v-bind="props" />
      <select
        v-if="!multiple"
        :id="forName"
        :name="forName"
        class="my-2 w-full p-2 pb-[0.5625rem]"
        :class="{
          border: !noChrome,
          '!bg-transparent': noChrome,
          'appearance-none': noChrome && disabled,
        }"
        :required="required"
        :disabled="disabled"
        :data-cy-id="`Select_${forName ?? label?.split('.').slice(-1)}`"
        @change="
          (ev) =>
            onSelectChange((ev.target as HTMLSelectElement).selectedOptions)
        "
        @focus="emit('focus')"
        @blur="emit('blur')"
      >
        <option v-if="placeholder" :selected="!modelValue" disabled value="">
          {{ t(placeholder) }}
        </option>
        <option
          v-for="r in _options"
          :key="r.key"
          :value="r.key"
          :selected="isSelected(r)"
          :disabled="disableEmptyOption && !r.key && !isSelected(r)"
        >
          {{ t(r.text) }}
        </option>
      </select>
    </label>
    <Listbox
      v-if="!!multiple"
      :id="forName"
      :name="forName"
      as="div"
      :multiple="canSelectArray"
      :disabled="disabled"
      :class="{ 'bg-gnist-white': !noChrome }"
      :model-value="arrSelectedKeys"
      @update:model-value="
        (val: string | string[] | undefined) => onListboxChange(val)
      "
    >
      <ListboxButton
        ref="listboxButtonRef"
        class="my-2 flex w-full items-center justify-between p-2 pb-0"
        :class="
          [
            arrSelectedItems?.length > 0
              ? 'text-gnist-black'
              : 'text-gnist-gray',
            { border: !noChrome },
          ].concat(buttonclass)
        "
        :data-cy-id="`ListBox_${forName ?? label?.split('.').slice(-1)}`"
      >
        <span
          class="ml-2 inline-flex w-full max-w-full shrink overflow-hidden text-ellipsis whitespace-nowrap pb-2"
        >
          <slot name="button" :selected-items="arrSelectedItems">
            <template
              v-for="(sv, index) of arrSelectedItems"
              :key="_getKey(sv)"
            >
              {{ t(getOption(_getKey(sv) ?? 'empty')?.text ?? '')
              }}{{ index + 1 < arrSelectedItems.length ? ',' : '' }}&nbsp;
            </template>
          </slot>
          <span v-if="arrSelectedItems.length == 0" class="items-center">
            {{ t(multiple?.emptyLabel ?? '') }}
          </span>
        </span>
        <div class="flex items-center self-start">
          <MaterialIcon
            aria-hidden="true"
            class="self-baseline text-2xl leading-none text-gnist-gray"
          >
            expand_more
          </MaterialIcon>
        </div>
      </ListboxButton>
      <ListboxOptions
        class="absolute inset-x-0 top-12 z-10 mx-1 my-1 min-w-max rounded bg-gnist-white p-1 shadow-md"
        :data-cy-id="`ListBoxOptions_${forName ?? label?.split('.').slice(-1)}`"
      >
        <ListboxOption
          v-for="(r, index) in _options"
          v-slot="{ active }"
          :key="index"
          :value="r.key"
          :selected="isSelected(r)"
          :data-cy-value="_getKey(r.value)"
          class="list-item"
        >
          <div
            :class="[
              canSelectArray ? 'p-2' : 'px-2 py-0',
              'flex items-center gap-2',
              { 'bg-[#767676]': active }, // Default active background for dropdowns (at least in Chrome)
              { 'text-gnist-white': active },
              { 'text-gnist-black': !active },
            ]"
          >
            <div
              v-if="canSelectArray"
              class="flex h-6 w-6 items-center rounded border p-0.5"
            >
              <MaterialIcon
                v-if="isSelected(r)"
                aria-hidden="true"
                class="self-baseline text-gnist-gray"
              >
                check
              </MaterialIcon>
            </div>
            <slot name="option" :item="r">
              <span>{{ r.text ? t(r.text) : '&nbsp;' }}</span>
            </slot>
          </div>
        </ListboxOption>
      </ListboxOptions>
    </Listbox>
  </div>
</template>

<style scoped>
select[disabled],
:deep([data-headlessui-state='disabled']) {
  @apply bg-gnist-gray-light;
}
</style>
