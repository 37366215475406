<script lang="ts">
export type IInputLabelProps = {
  label?: string;
  /** Set to true if the label is visually placed somewhere else, but should be kept here for accessibility */
  srOnly?: boolean;
  /** Set to true if there is no visible label, but the space that should be used by the label should be filled (e.g. to align with other inputs) */
  useHeight?: boolean;
  tooltip?: string;
  labelEnd?: string;
  labelClass?: string | string[];
  tooltipClass?: string | string[];
  required?: boolean;
  /** Used for connecting label and input */
  forName?: string;
  descriptionStyle?: 'tooltip' | 'inline';
  minlength?: number;
  maxlength?: number;
  placeholder?: string;
  isBlockField?: boolean;
  limitLabelStart?: boolean;
};
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import MaterialIcon from '@/components/MaterialIcon.vue';
import { useResizeListener } from '@/utilities/useResizeListener';
import { computed, useAttrs, type StyleValue } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<IInputLabelProps>();
const attrs = useAttrs();
const fallthroughAttrs = computed(
  (): { class?: unknown; style?: StyleValue } => {
    return { class: attrs.class, style: attrs.style as StyleValue };
  },
);

const { isBelowSmall } = useResizeListener();
</script>

<template>
  <div
    v-if="label"
    class="GnistInputLabel flex min-w-0 items-center justify-between leading-8"
    :class="{ 'h-8': useHeight }"
    v-bind="fallthroughAttrs"
    :data-is-block-field="isBlockField ? true : undefined"
  >
    <span
      :title="t(label)"
      class="flex min-w-0"
      :class="{
        'sr-only': srOnly,
        'w-full': !limitLabelStart,
        tooltip: isBelowSmall,
      }"
      :data-tip="t(label)"
    >
      <slot>
        <span class="block min-w-0 truncate text-left" :class="labelClass">
          {{ t(label) }}
        </span>
      </slot>
      <span
        v-if="required"
        class="text-gnist-red-dark"
        :class="labelClass"
        :title="t('validation.required')"
      >
        &nbsp;*
      </span>
      <span
        v-if="tooltip && descriptionStyle !== 'inline'"
        :data-tip="t(tooltip)"
        class="tooltip static ml-1 whitespace-break-spaces font-normal sm:relative"
        :class="tooltipClass"
      >
        <MaterialIcon
          class="text-2xl hover:text-secondary"
          :aria-describedby="`desc_${forName ?? label?.split('.').slice(-1)}`"
        >
          info
        </MaterialIcon>
      </span>
    </span>
    <slot name="labelEnd">
      <span
        v-if="labelEnd !== undefined"
        class="block self-end whitespace-nowrap"
      >
        {{ labelEnd }}
      </span>
    </slot>
  </div>
  <span
    v-if="tooltip"
    :id="`desc_${forName ?? label?.split('.').slice(-1)}`"
    class="whitespace-pre-wrap text-wrap text-sm leading-none text-gnist-black"
    :class="{ 'sr-only': descriptionStyle !== 'inline' }"
  >
    {{ t(tooltip) }}
  </span>
</template>
