<script lang="ts" setup>
import { type Category, colorList } from '@/api/types';
import TextInput from '@/components/forms/InputText.vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import InputBlockPicker from './forms/InputBlockPicker.vue';
import { mapValueList } from '@/utilities/mapValueList';
import { useI18n } from 'vue-i18n';
import { computed, toRef } from 'vue';
import { useInternalState } from '@/utilities/useInternalState';
import { cardTemplates, pageTemplates } from '@/renderTemplates';
import { useRoleMembers, useRoles } from '@/api/user';
import SchemaEditor from './SchemaEditor.vue';

const { t } = useI18n();

const props = defineProps<{
  modelValue: Partial<Category> | null;
  allItems?: Category[];
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: Category | null): void;
  (e: 'reportValidationError', name: string, hasError: boolean): void;
}>();

const item = useInternalState<Partial<Category> | null>(
  toRef(props, 'modelValue'),
  null,
  (val) => emit('update:modelValue', val as Category),
);

const colorOptions = mapValueList(colorList, 'categoryAdmin.colors', true);
const cardTemplateOptions = mapValueList(
  cardTemplates.map((t) => t.id),
  'templates',
  true,
);
const pageTemplateOptions = mapValueList(
  pageTemplates.map((t) => t.id),
  'templates',
  true,
);

const { result: categoryEditors } = useRoleMembers('category_editor');
const editorOptions = computed(() => {
  if (!categoryEditors.value) return [];
  return toDropdownItems(categoryEditors.value, (item) => [
    item.id,
    item.fullName ?? `${item.firstName} ${item.lastName}`,
  ]);
});

const { result: roles } = useRoles(['DocumentAdmin']);
const roleOptions = computed(() =>
  mapValueList(roles.value?.map((r) => r.name) ?? [], 'roleSelection'),
);
</script>

<template>
  <div v-if="item">
    <div class="flex flex-col gap-8">
      <div
        class="flex grow flex-col gap-8 md:grid md:grid-cols-[repeat(auto-fit,minmax(max(15rem,30%),1fr))]"
      >
        <TextInput
          v-model="(item as Category).name.no"
          label="categoryAdmin.nameNo"
          required
        />
        <TextInput
          v-model="(item as Category).name.en"
          label="categoryAdmin.nameEn"
          required
        />
        <Dropdown
          v-model="item.color"
          label="categoryAdmin.colors.title"
          :multiple="{ emptyLabel: '', maxItems: 1 }"
          :options="colorOptions"
          :getkey="(val) => val ?? 'empty'"
        >
          <template #button="{ selectedItems: [selColor] }">
            <span
              class="decoration items-center whitespace-nowrap underline decoration-4 underline-offset-4"
              :class="[`decoration-gnist-${selColor}`]"
            >
              {{ selColor ? t('categoryAdmin.colors.values.' + selColor) : '' }}
            </span>
          </template>
          <template #option="{ item: color }">
            <span
              class="mx-4 my-1 flex items-center whitespace-nowrap"
              :class="[
                color.value
                  ? `decoration underline decoration-4 underline-offset-4 decoration-gnist-${color.value}`
                  : '',
              ]"
            >
              {{ color.text ? t(color.text) : '&nbsp;' }}
            </span>
          </template>
        </Dropdown>
        <Dropdown
          v-model="item.cardTemplateId"
          label="categoryAdmin.template.card"
          :options="cardTemplateOptions"
          :getkey="(val) => val ?? 'empty'"
        />
        <Dropdown
          v-model="item.pageTemplateId"
          label="categoryAdmin.template.page"
          :options="pageTemplateOptions"
          :getkey="(val) => val ?? 'empty'"
        />
        <Dropdown
          v-model="item.editors"
          label="categoryAdmin.editors"
          :multiple="{ emptyLabel: '' }"
          :options="editorOptions"
          :getkey="(val) => val ?? 'empty'"
        />
        <Dropdown
          v-model="item.createRoles"
          label="categoryAdmin.roles.create"
          tooltip="categoryAdmin.roles.createTooltip"
          :multiple="{ emptyLabel: '' }"
          :options="roleOptions"
          :getkey="(val) => val ?? 'empty'"
        />
        <Dropdown
          v-model="item.publishRoles"
          label="categoryAdmin.roles.publish"
          tooltip="categoryAdmin.roles.publishTooltip"
          :multiple="{ emptyLabel: '' }"
          :options="roleOptions"
          :getkey="(val) => val ?? 'empty'"
        />
        <TextInput
          v-model="item.sortOrder"
          label="categoryAdmin.sortOrder"
          type="number"
        />
        <InputBlockPicker
          v-model="item.homePageId"
          label="categoryAdmin.homePageId"
          tooltip="categoryAdmin.homePageIdTooltip"
        />
      </div>
      <SchemaEditor
        v-model="item.schema"
        :card-template-id="item.cardTemplateId"
        :page-template-id="item.pageTemplateId"
      />
    </div>
  </div>
</template>
