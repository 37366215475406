<script lang="ts" setup>
import type { RouteItem, RouteItemDTO, PartialSome } from '@/api/types';
import ContentPage from '@/components/ContentPage.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import RouteEditor from '@/components/RouteEditor.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import {
  createRouteItem,
  deleteRouteItem,
  updateRouteItem,
  useRouteItems,
} from '@/api/menu';
import { useValidationTracking } from '@/utilities/useValidationTracking';
import { hardcodedStaticRoutes } from '@/router/routeUtils';
import TableComponent from '@/components/TableComponent.vue';

const { result: routeItems, refresh } = useRouteItems();

const selectedItem = ref<PartialSome<RouteItemDTO, 'id'> | null>(null);
const showDetailsModal = ref(false);

function viewRouteDetails(
  routeItem: NonNullable<typeof selectedItem.value>,
  updateTriggerEvent?: MouseEvent,
) {
  if (updateTriggerEvent?.defaultPrevented) return;
  if (updateTriggerEvent) {
    showValidationMessages.value = true; // Show validation messages when editing (in case the validation requirements have changed)
    setTimeout(() => (showValidationMessages.value = false), 1000); // Stop forcing validation messages after a short while (to avoid changing focus each time another value is entered)
  }
  selectedItem.value = routeItem;
  showDetailsModal.value = true;
}

function closeRouteDetails() {
  showDetailsModal.value = false;
  // Delay resetting the selected user to avoid the user information disappearing from
  // the dialog before it's closed
  setTimeout(() => (selectedItem.value = null), 200);
}

async function onSave() {
  if (!selectedItem.value) return;
  if (!isValid.value) {
    showValidationMessages.value = true;
    return;
  }
  showValidationMessages.value = false;
  if (selectedItem.value.id === undefined) {
    const updatedItem = await createRouteItem(selectedItem.value);
    routeItems.value?.push(updatedItem);
  } else {
    const updatedItem = await updateRouteItem(selectedItem.value);
    selectedItem.value.routeName = updatedItem.routeName;
  }
  refresh?.();
  closeRouteDetails();
}

async function onCreateButtonClick() {
  if (!routeItems.value) return;
  const newItem: PartialSome<RouteItemDTO, 'id'> &
    Pick<RouteItem, 'routeType'> = {
    routeName: null as unknown as string,
    text: { no: '' },
    routeType: 'page',
    targetMenu: 'main',
    id: undefined,
  };
  viewRouteDetails(newItem);
}

const showDeleteModal = ref(false);
const itemToDelete = ref<RouteItemDTO>();
async function doDelete() {
  if (!itemToDelete.value?.id) return;
  await deleteRouteItem(itemToDelete.value?.id);
  refresh?.();
  showDeleteModal.value = false;
  routeItems.value =
    routeItems.value?.filter((i) => i.id !== itemToDelete.value?.id) ?? [];
  itemToDelete.value = undefined;
}

const { t } = useI18n();

const showValidationMessages = ref<boolean>(false);
const { onValidChanged, formRef, isValid } = useValidationTracking(
  selectedItem,
  showValidationMessages,
  true,
);
</script>
<template>
  <ContentPage :title="t('routeAdmin.title')" class="bg-gnist-gray-light-light">
    <ModalComponent
      :show-modal="showDetailsModal"
      :title="t('routeAdmin.viewRoute')"
      size="big"
      show-overflow
      @close="closeRouteDetails"
    >
      <template #default>
        <form ref="formRef">
          <RouteEditor
            v-model="selectedItem"
            :all-items="routeItems ?? []"
            @report-validation-error="
              (name, hasError) => onValidChanged(name, !hasError)
            "
          />
        </form>
      </template>
      <template #buttons>
        <div className="flex justify-end">
          <div class="gnist-button-group">
            <ButtonComponent
              :text="t('buttons.cancel')"
              @click="closeRouteDetails"
            />
            <ButtonComponent
              :text="t('buttons.save')"
              :type="'primary'"
              :disabled="!isValid"
              @click="onSave"
            />
          </div>
        </div>
      </template>
    </ModalComponent>

    <div class="pb-8">
      <ButtonComponent
        :text="t('routeAdmin.create')"
        @click="onCreateButtonClick"
      />
      <RouterLink
        :to="{ path: '/usermanual/about_routes' }"
        target="_blank"
        class="mx-4 underline hover:bg-gnist-gray-light"
      >
        {{ t('routeAdmin.help') }}
      </RouterLink>
      <TableComponent
        size="table-xs"
        :rows="routeItems"
        show-spinner
        i18n-key="routeAdmin"
        :get-key="(routeItem) => routeItem.id"
        :columns="[
          'text',
          'parent',
          'routeName',
          'routeType.title',
          'sortOrder',
          null,
        ]"
        row-class="cursor-pointer"
        @click:row="(routeItem, ev) => viewRouteDetails(routeItem, ev)"
      >
        <template #columnHeader="{ item: routeItem }">
          {{ routeItem.text.no }}
        </template>
        <template #columns="{ item: routeItem }">
          <td>
            {{
              routeItems!.find((i) => i.routeName == routeItem.parent)?.text.no
            }}
          </td>
          <td>
            {{
              routeItem.routeType === 'hardcoded'
                ? t('routeAdmin.routeType.values.hardcoded') +
                  ': ' +
                  t(
                    hardcodedStaticRoutes.find(
                      (r) => r.name === routeItem.routeName,
                    )?.sourceTitle ?? '',
                  )
                : routeItem.routeName
            }}
          </td>
          <td>
            {{ t('routeAdmin.routeType.values.' + routeItem.routeType) }}
          </td>
          <td>
            {{ routeItem.sortOrder }}
          </td>
          <td class="cursor-default" @click.stop>
            <ButtonComponent
              :text="t('routeAdmin.delete.button')"
              class="gnist-button gnist-button-danger"
              @click.prevent="
                (ev) => {
                  ev.preventDefault();
                  itemToDelete = routeItem;
                  showDeleteModal = true;
                }
              "
            />
          </td>
        </template>
      </TableComponent>
      <ModalComponent
        :show-modal="showDeleteModal"
        :title="t('routeAdmin.delete.title')"
        @close="
          () => {
            itemToDelete = undefined;
            showDeleteModal = false;
          }
        "
        @handle-click="doDelete"
      >
        <template #default>
          <p class="py-2">
            <strong>{{ t('routeAdmin.routeName') }}: </strong
            >{{ itemToDelete?.routeName }}
          </p>
          <p>{{ t('routeAdmin.delete.warning') }}</p>
        </template>
      </ModalComponent>
    </div>
  </ContentPage>
</template>

<style scoped>
:deep(th),
:deep(td) {
  text-align: left;
  padding: 1rem 0.5rem;
  text-overflow: ellipsis;
}
</style>
