<script setup lang="ts">
import { ref, computed } from 'vue';
import ButtonComponent from './ButtonComponent.vue';
import ImageInput from '@/components/forms/InputImage.vue';
import LinkInput from '@/components/forms/InputLink.vue';
import TextInput from '@/components/forms/InputText.vue';
import {
  EMPTY_GUID,
  type BlockImage,
  type Link,
  type OrganizationData,
} from '@/api/types';
import { useI18n } from 'vue-i18n';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import { useStoreAbility } from '@/abilities';
import {
  createOrganization,
  updateOrganization,
  requestMembership,
} from '@/api/organization';
import { getBlobFilename, getBlobUrl } from '@/api/blob';
import { useValidationTracking } from '@/utilities/useValidationTracking';
import { useCurrentUser } from '@/api/auth';

const { t } = useI18n();
const { can } = useStoreAbility();

const userInfo = useCurrentUser();
const memberOrgs = computed(() => userInfo.value?.memberOrgs);
const requestedOrgs = computed(() => userInfo.value?.requestedOrgs ?? []);

const props = defineProps<{
  data: OrganizationData;
  isCreate?: boolean;
  allOrgs?: OrganizationData[] | null;
}>();

const emit = defineEmits<{
  (e: 'onSaved', data: OrganizationData): void;
  (e: 'closeModal'): void;
}>();

function toInternalState(externalState: OrganizationData): Omit<
  OrganizationData,
  'logo' | 'webpage'
> & {
  logo: Pick<BlockImage, 'name'>;
  webpage: Pick<Link, 'url'>;
} {
  return {
    ...externalState,
    logo: { name: getBlobFilename('/organization', externalState.logo) },
    webpage: { url: externalState.webpage },
  };
}

const inputData = ref(toInternalState(props.data));
const { onValidChanged, isValid } = useValidationTracking(
  inputData,
  undefined,
  true,
);

async function onSave() {
  let organizationData = {
    ...inputData.value,
    logo: inputData.value.logo.name
      ? getBlobUrl('/organization', inputData.value.logo.name)
      : '',
    webpage: inputData.value.webpage.url,
  } satisfies OrganizationData;
  if (props.data.organizationId === EMPTY_GUID) {
    const result = await createOrganization(organizationData);
    organizationData.organizationId = result.organizationId;
  } else {
    organizationData = await updateOrganization(organizationData);
    inputData.value = toInternalState(organizationData);
  }
  emit('onSaved', organizationData);
}

const submitDisabled = computed(() => {
  if (!props.data.name) return !inputData.value.name;

  const unChanged =
    inputData.value.name === props.data.name &&
    inputData.value.webpage.url == props.data.webpage &&
    inputData.value.logo.name ===
      getBlobFilename('/organization', props.data.logo) &&
    inputData.value.isInternal === props.data.isInternal;

  return !isValid.value || !inputData.value.name || unChanged;
});

const filteredOrganizationData = computed(() =>
  props.allOrgs?.filter((org) =>
    org.name.toLowerCase().includes(inputData.value.name.toLowerCase()),
  ),
);

async function requestMembershipOfOrg(organization: OrganizationData) {
  if (!userInfo.value?.email) return;
  await requestMembership(organization.organizationId, userInfo.value.email);
  userInfo.value?.requestedOrgs?.push(organization.organizationId);
}
</script>

<template>
  <div
    v-if="inputData"
    class="grid w-[80rem] max-w-full gap-6"
    :class="[isCreate ? 'grid-cols-[1fr_2fr] overflow-auto' : '']"
  >
    <div class="mx-1 max-h-full min-h-0">
      <div v-if="can('update', 'Organization')" class="my-2">
        <Checkbox
          v-model="inputData.isInternal"
          label="organization.internalDepartment"
          direction="horizontal"
          mode="toggle"
        />
      </div>
      <div class="my-2">
        <TextInput
          v-model="inputData.name"
          :label="
            inputData.isInternal
              ? t('organization.departmentName')
              : t('organization.orgName')
          "
          required
          show-validation-message
        />
      </div>
      <div class="my-2">
        <LinkInput
          v-model="inputData.webpage"
          for-name="webpage"
          :label="
            inputData.isInternal
              ? 'organization.internalHomepage'
              : 'organization.homepage'
          "
          tooltip="organization.homepageTooltip"
          :tooltip-class="[
            isCreate ? '' : 'tooltip-left lg:tooltip-top',
            'ml-auto !relative',
          ]"
          inline
          hide-text
          hide-description
          show-validation-message
          @update:valid="(valid) => onValidChanged('webpage', valid)"
        />
      </div>
      <div class="my-2 w-full">
        <ImageInput
          v-model="inputData.logo"
          for-name="image"
          :label="
            inputData.isInternal
              ? 'organization.internalLogo'
              : 'organization.logo'
          "
          tooltip="organization.logoTooltip"
          :tooltip-class="[
            isCreate ? '' : 'tooltip-left lg:tooltip-top',
            'ml-auto !relative',
          ]"
          blob-location="/organization"
          hide-description-input
          border
        />
      </div>
    </div>
    <div
      v-if="isCreate"
      class="flex max-h-full min-h-0 flex-col overflow-hidden"
    >
      <h3 class="mb-2 text-lg">{{ t('organization.exisingOrgName') }}</h3>
      <div
        class="w-full overflow-y-auto rounded border border-solid text-black"
      >
        <template v-if="filteredOrganizationData">
          <div
            v-for="organization in filteredOrganizationData"
            :key="organization.organizationId"
            class="flex cursor-default items-center bg-gnist-white even:bg-gnist-gray-light-light hover:bg-gnist-blue-light-light hover:bg-opacity-50"
          >
            <div class="w-full px-4">
              {{ organization.name }}
            </div>
            <div class="flex min-h-12 items-center p-1">
              <p
                v-if="memberOrgs?.includes(organization.organizationId)"
                class="badge badge-outline badge-lg border-gnist-gray bg-gnist-purple"
              >
                {{ t('organization.isMember') }}
              </p>
              <p
                v-else-if="requestedOrgs?.includes(organization.organizationId)"
                class="badge badge-outline badge-lg whitespace-nowrap border-gnist-gray bg-gnist-orange"
              >
                {{ t('organization.requestedMembership') }}
              </p>
              <ButtonComponent
                v-else
                :text="t('organization.requestMembership')"
                :disabled="
                  memberOrgs?.includes(organization.organizationId) ||
                  requestedOrgs?.includes(organization.organizationId)
                "
                @click="requestMembershipOfOrg(organization)"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div :class="[isCreate ? 'col-start-2' : '']">
      <div className="flex justify-end">
        <div class="gnist-button-group p-1">
          <ButtonComponent
            v-if="isCreate"
            :text="t('buttons.cancel')"
            @click="$emit('closeModal')"
          />
          <ButtonComponent
            type="primary"
            :text="t('buttons.save')"
            :submit="true"
            :disabled="submitDisabled"
            @click="onSave"
          />
        </div>
      </div>
    </div>
  </div>
</template>
