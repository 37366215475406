import { apiUrl } from '@/config';
import {
  compose,
  composeLazy,
  composeWithRefresh,
  type ComposableResult,
  type LazyComposableResult,
} from './composable';
import type { Tag, TagUsage } from './types';
import { isRef, type Ref } from 'vue';

const tagUrl = apiUrl + '/tags';

export function useTags(
  forUsageId?: Ref<number | undefined>,
  delayInit?: boolean,
): LazyComposableResult<Tag[]>;
export function useTags(forUsageId?: number): ComposableResult<Tag[]>;
export function useTags(
  forUsageId?: number | Ref<number | undefined>,
  delayInit?: boolean,
): ComposableResult<Tag[]> | LazyComposableResult<Tag[]> {
  if (isRef(forUsageId)) {
    const composable = composeLazy(() => {
      if (!forUsageId.value) return [];
      return getTags(forUsageId.value);
    }, forUsageId);
    if (!delayInit) composable.init();
    return composable;
  } else {
    return compose(getTags(forUsageId));
  }
}
async function getTags(forUsageId?: number): Promise<Tag[]> {
  const query = forUsageId ? `?usageId=${forUsageId}` : '';
  const response = await fetch(`${tagUrl}${query}`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}

export const createTag = async (
  tag: Omit<Tag, 'id' | 'usages'>,
): Promise<Tag> => {
  const response = await fetch(tagUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(tag),
  });
  if (!response.ok) throw new Error('error.create_failed');
  return await response.json();
};
export const updateTag = async (tag: Tag): Promise<Tag> => {
  const response = await fetch(`${tagUrl}/${tag.id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(tag),
  });
  if (!response.ok) throw new Error('error.update_failed');
  return await response.json();
};
export const deleteTag = async (tagId: number): Promise<void> => {
  const response = await fetch(`${tagUrl}/${tagId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.delete_failed');
};

const allTagUsagesLazy = composeLazy(() => getTagUsages());
export function useTagUsagesLazy(): LazyComposableResult<TagUsage[]> {
  allTagUsagesLazy.init();
  return allTagUsagesLazy;
}
export function useTagUsages(): ComposableResult<TagUsage[]> {
  return compose(getTagUsages());
}
async function getTagUsages(): Promise<TagUsage[]> {
  const response = await fetch(`${tagUrl}/usages`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}
export function useTagUsage(
  usageId: number | Ref<number | undefined>,
): ComposableResult<TagUsage> {
  if (isRef(usageId)) {
    return composeWithRefresh(() => {
      if (!usageId.value) return null;
      return getTagUsage(usageId.value);
    }, usageId);
  } else {
    return compose(getTagUsage(usageId));
  }
}
async function getTagUsage(usageId: number): Promise<TagUsage> {
  const response = await fetch(`${tagUrl}/usages/${usageId}`, {
    method: 'GET',
    credentials: 'include',
  });
  if (!response.ok) throw new Error(await response.text());
  return await response.json();
}
export const createTagUsage = async (
  usage: Omit<TagUsage, 'id' | 'tags'>,
): Promise<TagUsage> => {
  const response = await fetch(`${tagUrl}/usages`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(usage),
  });
  if (!response.ok) throw new Error('error.create_failed');
  return await response.json();
};
export const updateTagUsage = async (
  usageId: number,
  usage: Omit<TagUsage, 'tags'>,
): Promise<TagUsage> => {
  const response = await fetch(`${tagUrl}/usages/${usageId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(usage),
  });
  if (!response.ok) throw new Error('error.update_failed');
  return await response.json();
};
export const deleteTagUsage = async (usageId: number): Promise<void> => {
  const response = await fetch(`${tagUrl}/usages/${usageId}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.delete_failed');
};
