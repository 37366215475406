<script lang="ts" setup>
import { type PageTemplateProps } from '@/api/types';
import BlockPageBanner from '@/components/block/BlockPageBanner.vue';
import BlockRenderer from '@/components/block/BlockRenderer.vue';
import ImageRenderer from '@/components/block/ImageRenderer.vue';
import BlockToc from '@/components/block/BlockToc.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<PageTemplateProps>();
const containers = ref<{ idx: number; el: HTMLElement }[]>([]);
</script>

<template>
  <BlockPageBanner
    :title="block.name ?? ''"
    :block="block"
    :tags="block.tags ?? []"
    :class="[`bg-gnist-${bgColor}`]"
    :is-preview="!!isPreview"
  >
    <template #leftMargin>
      <ImageRenderer
        v-if="block.ownerOrg?.logo"
        :value="{ fullUrl: block.ownerOrg.logo, alt: t('block.ownerLogo') }"
        class="mx-auto mb-4 max-h-32"
      />
    </template>
    <template #description>
      {{ `${t('productPage.suppliedBy')} ${block.ownerOrg?.name ?? ''}` }}
      <span v-if="block.ownerOrg?.webpage">
        (<a :href="block.ownerOrg.webpage" target="_blank">{{
          block.ownerOrg.webpage
        }}</a
        >)
      </span>
    </template>
  </BlockPageBanner>
  <div class="w-full text-gnist-black" :class="[`bg-gnist-${bgColor}`]">
    <div class="gnist-page-width grow pb-16 lg:grid lg:grid-cols-4 lg:gap-10">
      <div class="relative col-span-1 ml-auto pt-8 lg:pb-24">
        <BlockToc v-if="block" :containers="containers" />
      </div>
      <div class="col-span-3">
        <!-- Content -->
        <div
          class="mx-5 mt-10"
          data-cy-id="contentContainer"
          :data-cy-state="!!block.content ? 'loaded' : 'not_loaded'"
        >
          <BlockRenderer
            v-model:containers="containers"
            :block="block"
            class="mt-8 first:mt-0"
          />
        </div>
      </div>
    </div>
  </div>
</template>
