<script setup lang="ts">
import ContentPage from '@/components/ContentPage.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TagUsageEditor from '@/components/TagUsageEditor.vue';
import { useI18n } from 'vue-i18n';
import {
  createTagUsage,
  deleteTagUsage,
  updateTagUsage,
  useTagUsages,
} from '@/api/tags';
import {
  removeParentKey,
  type KeyOfRecursive,
  type KeyOfRecursiveBelow,
  type TagUsage,
  getEmptyLocaleValue,
} from '@/api/types';
import { SUPPORTED_LOCALES, type supported_locale } from '@/config';
import { computed, ref } from 'vue';
import TableComponent from '@/components/TableComponent.vue';

const { t, locale } = useI18n();

const { result: usages } = useTagUsages();

const usageEditors = ref<InstanceType<typeof TagUsageEditor>[]>([]);

const changeList = ref<{ [id: number]: boolean }>({});
const hasChanges = computed(
  () =>
    Object.values(changeList.value).includes(true) ||
    removedUsages.value.length > 0,
);

async function save() {
  if (!hasChanges.value) return;
  if (!usages.value) return;
  for (const [id, hasChange] of Object.entries(changeList.value)) {
    if (!hasChange) continue;
    const usageToSave = usages.value.find((u) => `${u.id}` === id);
    if (!usageToSave) throw new Error('Should not happen');
    if (usageToSave.id === -1) {
      const newUsage = await createTagUsage(usageToSave);
      usageToSave.id = newUsage.id;
    } else {
      await updateTagUsage(usageToSave.id, usageToSave);
    }
  }
  changeList.value = {};
  for (const usageToDelete of removedUsages.value) {
    await deleteTagUsage(usageToDelete);
  }
  removedUsages.value.length = 0;
  usageEditors.value?.forEach((e) => e.resetSource());
}

const textPropPrefix: `${keyof Pick<TagUsage, 'label'>}.` = 'label.';
const locales = [...SUPPORTED_LOCALES]
  .sort((a, b) => (a === locale.value ? -1 : b === locale.value ? 1 : 0))
  .map((i): KeyOfRecursiveBelow<TagUsage, 'label'> => `${textPropPrefix}${i}`);

const columns = computed(() => {
  const cols: (KeyOfRecursive<TagUsage> | null)[] = [...locales];
  cols.push('homePageId');
  cols.push('color');
  cols.push(null);
  return cols;
});

function getColumnHeader(column: KeyOfRecursive<TagUsage>) {
  if (!locales.includes(column as KeyOfRecursiveBelow<TagUsage, 'label'>)) {
    return t(`tagEditor.usageColumns.${column}`);
  } else {
    return (
      t(`language.values.${removeParentKey(column, 'label')}`) +
      t('tagEditor.usageColumns.label')
    );
  }
}

function onCreateButtonClick() {
  if (!usages.value) return;
  const minId = Math.min(...usages.value.map((u) => u.id), 0);
  usages.value.push({
    id: minId - 1,
    label: getEmptyLocaleValue(),
  });
}

const removedUsages = ref<number[]>([]);
function removeUsage(usageId: number) {
  if (!usages.value) return;
  removedUsages.value.push(usageId);
  usages.value = usages.value.filter((u) => u.id !== usageId);
}
</script>

<template>
  <ContentPage
    :title="t('tagEditor.usageTitle')"
    class="bg-gnist-gray-light-light"
  >
    <section class="py-8">
      <div class="w-full max-w-full">
        <div class="my-6 flex justify-between">
          <ButtonComponent
            :text="t('tagEditor.createUsage')"
            @click="onCreateButtonClick"
          />
          <ButtonComponent
            :text="t('tagEditor.save')"
            type="primary"
            :disabled="!hasChanges"
            @click="() => save()"
          />
        </div>
        <TableComponent
          size="table-xs"
          :rows="usages"
          show-spinner
          i18n-key="tagEditor.usageColumns"
          :get-key="(usage) => usage.id"
          :columns="columns"
          :get-column-header="getColumnHeader"
          :sort-fields="locales as KeyOfRecursive<TagUsage>[]"
          :default-sortfield="`label.${locale as supported_locale}`"
          skip-sort-on-content-change
        >
          <template #columns="{ item: usage, rowIndex: idx, items }">
            <TagUsageEditor
              :ref="
                (ref) =>
                  usageEditors.push(ref as InstanceType<typeof TagUsageEditor>)
              "
              v-model="items[idx]"
              :locale-keys="locales"
              @update:changed="(val) => (changeList[usage.id] = val)"
              @remove="removeUsage(usage.id)"
            />
          </template>
        </TableComponent>
      </div>
    </section>
  </ContentPage>
</template>
