<script setup lang="ts">
import type { Tag } from '@/api/types';
import { translateStringOrLocale } from '@/i18n';
import { computed } from 'vue';
import TagRenderer from '@/components/TagRenderer.vue';

const props = defineProps<{
  tags?: Pick<Tag, 'text' | 'color'>[];
}>();

const translatedTagList = computed(() =>
  props.tags?.map((t) => '#' + translateStringOrLocale(t.text).value).join(' '),
);
</script>

<template>
  <div
    class="self-end"
    :class="{ tooltip: translatedTagList }"
    :data-tip="translatedTagList"
  >
    <div
      v-if="(tags?.length ?? 0) > 0"
      class="flex h-6 flex-row flex-wrap justify-end gap-1 overflow-hidden"
    >
      <TagRenderer :tags="tags" container-class="h-6 flex items-center" />
    </div>
  </div>
</template>
