import {
  computed,
  isRef,
  nextTick,
  type ComputedRef,
  type Ref,
  type ComputedGetter,
} from 'vue';
import {
  createI18n,
  type Composer,
  type I18nOptions,
  type I18n,
} from 'vue-i18n';
import type { Router, RouteLocationNormalized } from 'vue-router';
import {
  type Translated,
  type localeValue,
  type BlockValueLegacyAndArrayItem,
  type BlockValueWithLocaleDate,
  type ObjectBlockValue,
  type BlockLocaleValue,
  type ObjectSchemaType,
  emptyBlockValues,
  type BlockValueItemOnly,
  type SchemaType,
  type InteractiveImage,
  type ComplexBlockValueTypes,
  stringSchemaTypeValues,
  type StringSchemaType,
  complexSchemaTypeValues,
  type ComplexSchemaType,
  type LayoutItem,
  type BlockValueAndArrayItem,
  type BlockValueExceptLegacy,
  type ArrayItem,
  type BlockValue,
  type OnlyArray,
} from '@/api/types';
import { getRouteTitle } from '@/utilities/routeUtils';
import {
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
  type supported_locale,
} from '@/config';
import {
  setInteractiveImageBlockLocaleValues,
  translateInteractiveImage,
} from '@/utilities/translateInteractiveImage';
import {
  setLayoutItemBlockLocaleValues,
  translateLayoutItem,
} from '@/utilities/translateLayoutItem';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomI18n = I18n<any, any, any, string, false>;
let i18nInstance: CustomI18n;
export function getComposer(): Composer {
  return i18nInstance.global;
}
const locale_storage_key = 'gnist_locale';
export function set_locale(
  locale: supported_locale,
  route: RouteLocationNormalized,
) {
  localStorage.setItem(locale_storage_key, locale);
  setI18nLanguage(i18nInstance, locale);
  if (route.meta?.title) {
    document.title = getRouteTitle(getComposer().t(route.meta.title));
  }
}
export function setupI18n() {
  let selected_locale = localStorage.getItem(locale_storage_key) ?? '';
  if (!SUPPORTED_LOCALES.includes(selected_locale as supported_locale)) {
    selected_locale = DEFAULT_LOCALE;
  }
  const options = {
    legacy: false,
    locale: selected_locale, // default locale for the user

    // if a key is missing from the users's current locale, first try to
    // find it in the default locale
    fallbackLocale: [DEFAULT_LOCALE],
    missingWarn: false,
  } satisfies I18nOptions;
  i18nInstance = createI18n(options);

  setI18nLanguage(i18nInstance, options.locale);
  return i18nInstance;
}

function setI18nLanguage(i18n: CustomI18n, locale: string) {
  if (isRef(i18n.global.locale)) {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html')?.setAttribute('lang', locale);
}

export async function loadLocaleMessages(i18n: CustomI18n, locale: string) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  );

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}
export const localeData: { [locale: string]: Record<string, string> } = {
  no: {},
  en: {},
};
export function addLocaleData(
  data: string | localeValue | undefined,
  typeId: string,
  itemId: string,
) {
  if (data !== null && typeof data === 'object') {
    for (const [locale, message] of Object.entries(data)) {
      if (message) localeData[locale][`${typeId}.${itemId}`] = message;
    }
  } else if (typeof data === 'string') {
    addLocaleData({ no: data }, typeId, itemId);
  }
}
export async function loadDynamicLocaleMessages(router?: Router) {
  for (const [locale, messages] of Object.entries(localeData)) {
    i18nInstance.global.mergeLocaleMessage(locale, messages);
  }
  if (router?.currentRoute.value.meta?.title) {
    document.title = getRouteTitle(
      getComposer().t(router.currentRoute.value.meta.title),
    );
  }
}
export async function addRouterTitleTranslation(router: Router) {
  // This allows for i18n keys to be added as titles in the router
  const composer = getComposer();
  router.afterEach((to) => {
    nextTick(() => {
      if (to.meta.title) {
        document.title = getRouteTitle(composer.t(to.meta.title));
      }
    });
  });
}

export type TranslatedWithSource<T extends BlockValueAndArrayItem> =
  T extends BlockLocaleValue
    ? Translated<BlockLocaleValue>
    : T extends Array<infer U extends ObjectBlockValue>
      ? TranslatedWithSource<U>[]
      : Translated<T> & { _source: T };
export function attachSource<
  T extends ObjectBlockValue | ComplexBlockValueTypes,
  TReturn = TranslatedWithSource<T>,
>(val: Translated<T>, _source: T): TReturn {
  return { ...val, _source } as TReturn;
}

/** returns a string for localeData and legacy pure strings, for objects and arrays of objects each item will have the original source attached */
export function translateBlockValue<
  TValue extends BlockValueLegacyAndArrayItem,
>(
  blockValue: TValue,
  locale: supported_locale,
  schemaType: SchemaType,
  addFallbackInfo = false,
): TranslatedWithSource<BlockValueExceptLegacy<TValue>> {
  type RetType = TranslatedWithSource<BlockValueExceptLegacy<TValue>>;
  // Will only be hit if data is in legacy format
  if (typeof blockValue === 'string') {
    return getTranslatedValueWithFallback(
      { [DEFAULT_LOCALE]: blockValue },
      locale,
      addFallbackInfo,
    ) as Translated<BlockLocaleValue> & RetType;
  }
  // Standard string values (saved as localeValue)
  if (isLocaleValue(blockValue)) {
    return getTranslatedValueWithFallback(
      blockValue,
      locale,
      addFallbackInfo,
    ) as RetType;
  }
  // Array values (for now "Step"), not sure if this will work with a string array (inlcuding an array of localeData), if that is needed we might need something smarter
  if (Array.isArray(blockValue)) {
    return blockValue.map((i) =>
      translateBlockValue(i, locale, schemaType, addFallbackInfo),
    ) as RetType;
  }
  // Below here, the data object is guaranteed to be an object (and not an array)
  const objBlockValue = blockValue as ObjectBlockValue;
  if (schemaType === 'interactive_image') {
    const translatedValue = translateInteractiveImage(
      blockValue as InteractiveImage,
      locale,
      addFallbackInfo,
    );
    return attachSource(translatedValue, blockValue as InteractiveImage);
  } else if (schemaType === 'layout') {
    return translateLayoutItem(
      blockValue as LayoutItem,
      locale,
      addFallbackInfo,
    ) as RetType;
  }
  if (complexSchemaTypeValues.includes(schemaType as ComplexSchemaType)) {
    throw new Error('Unhandled complex block value type');
  }
  const translatedValue = Object.fromEntries(
    Object.entries(objBlockValue).map(([key, value]) => {
      if (typeof value === 'string') {
        const shouldBeTranslated = isLocaleValue(
          emptyBlockValues[schemaType as ObjectSchemaType]?.[
            key as keyof ObjectBlockValue
          ],
        );
        if (shouldBeTranslated) value = { [DEFAULT_LOCALE]: value };
      }
      return [
        key,
        isLocaleValue(value)
          ? getTranslatedValueWithFallback(value, locale, addFallbackInfo)
          : value,
      ];
    }),
  ) as Translated<Exclude<ObjectBlockValue, ComplexBlockValueTypes>>; // ComplexBlockValueTypes, array, localeValue and string is handled above
  return attachSource(translatedValue, objBlockValue);
}

function getTranslatedValueWithFallback(
  blockValue: localeValue,
  locale: supported_locale,
  addFallbackInfo: boolean,
): string | number | Date {
  const savedLocaleToUse: supported_locale =
    locale in blockValue
      ? locale
      : DEFAULT_LOCALE in blockValue
        ? DEFAULT_LOCALE
        : SUPPORTED_LOCALES.find((loc) => loc in blockValue) ?? DEFAULT_LOCALE;
  const value = blockValue[savedLocaleToUse] ?? '';
  if (isNumberOrDate(value)) return value;
  if (savedLocaleToUse !== locale) {
    console.debug(
      `No saved data for "${locale}", fallback to saved data in locale "${savedLocaleToUse}"`,
    );
    return value === '' || !addFallbackInfo
      ? value
      : getFallbackInfoText(locale, savedLocaleToUse) + value;
  }
  return value;
}

function isNumberOrDate(value: unknown): value is number | Date {
  return (
    typeof value === 'number' ||
    value instanceof Date ||
    !isNaN(parseInt(value?.toString() ?? '')) ||
    !isNaN(Date.parse(value?.toString() ?? ''))
  );
}

function getFallbackInfoText(
  locale: supported_locale,
  savedLocaleToUse: supported_locale,
  excludeLanguage = false,
) {
  const composer = getComposer();
  const language = composer.t(
    `language.values.${savedLocaleToUse}`,
    {},
    { locale },
  );
  const text = composer.t(
    'admin.blockProduction.translate.fallbackUsed',
    { language },
    { locale },
  );
  if (excludeLanguage) {
    return text.substring(0, text.indexOf(language));
  }
  return text + '\n\n';
}

function startsWithFallbackInfoText(
  locale: supported_locale,
  text: string | undefined,
) {
  if (typeof text !== 'string') return false;
  const introText = getFallbackInfoText(locale, 'no', true);
  return text?.startsWith(introText) ?? false;
}

function isNewlyAddedFallback(
  locale: supported_locale,
  currentValue: string | undefined,
  newValue: string | undefined,
) {
  const currentIsFallback = startsWithFallbackInfoText(locale, currentValue);
  const newIsFallback = startsWithFallbackInfoText(locale, newValue);
  return !currentIsFallback && newIsFallback;
}

function ensurePropertyIsLocaleValue<T extends ObjectBlockValue>(
  sourceObject: T,
  key: keyof T,
  schemaType: ObjectSchemaType,
) {
  const sourceValue = sourceObject[key];
  // If the old value in the source object is an object, it is either already a localeValue, or it is not something we should translate directly
  if (typeof sourceValue === 'object') return;
  const shouldBeTranslated = isLocaleValue(
    (emptyBlockValues[schemaType] as T)[key],
  );
  if (shouldBeTranslated) {
    // Set property as localeValue if it should be (and is not)
    (sourceObject[key] as localeValue) = {
      [DEFAULT_LOCALE]: sourceValue as string,
    };
  }
}
function setPossiblyTranslatableProperty<T extends ObjectBlockValue>(
  newValue: TranslatedWithSource<T>,
  sourceObject: T,
  key: keyof T,
  locale: supported_locale,
  schemaType: ObjectSchemaType,
): { newValue: string; currentValue: string | undefined } | null {
  ensurePropertyIsLocaleValue(sourceObject, key, schemaType);
  const newProperty = newValue[key as unknown as keyof TranslatedWithSource<T>];
  if (isLocaleValue(sourceObject[key])) {
    const currentValue = (sourceObject[key] as localeValue)[locale];
    (sourceObject[key] as localeValue)[locale] = newProperty as string; // Update value for current locale
    return { newValue: newProperty as string, currentValue };
  } else {
    (sourceObject[key] as unknown) = newProperty; // Typing breaks down here, but it should be okay.
    return null;
  }
}
function hasArrayItems(
  val: TranslatedWithSource<Exclude<BlockValueAndArrayItem, BlockLocaleValue>>,
): val is OnlyArray<
  TranslatedWithSource<Exclude<BlockValueAndArrayItem, BlockLocaleValue>>
> {
  return Array.isArray(val);
}
export function setBlockLocaleValueInObject<
  T extends Exclude<BlockValueAndArrayItem, BlockLocaleValue>,
>(
  newValue: TranslatedWithSource<T>,
  schemaType: ObjectSchemaType,
  locale: supported_locale,
): T extends BlockValueItemOnly ? BlockValueWithLocaleDate<T> : T {
  type RetType = T extends BlockValueItemOnly ? BlockValueWithLocaleDate<T> : T;
  if (hasArrayItems(newValue)) {
    return newValue.map((arrVal) =>
      setBlockLocaleValueInObject<ArrayItem<BlockValue>>(
        arrVal,
        schemaType,
        locale,
      ),
    ) as unknown as RetType; // This typing is too much for typescript to handle - but it works out in the end!
  }
  if (complexSchemaTypeValues.includes(schemaType as ComplexSchemaType)) {
    const retVal: ComplexBlockValueTypes | undefined = undefined;
    const hasNewlyAddedFallback = false; // TODO: handle this bit (if needed)
    const source = (
      newValue as { _source: ComplexBlockValueTypes & BlockValueItemOnly }
    )['_source'];
    delete (newValue as { _source?: unknown })['_source'];
    if (schemaType === 'interactive_image') {
      return setInteractiveImageBlockLocaleValues(
        newValue as Translated<InteractiveImage>,
        source as InteractiveImage,
        locale,
      ) as RetType;
    } else if (schemaType === 'layout') {
      return setLayoutItemBlockLocaleValues(
        newValue as Translated<LayoutItem>,
        source as LayoutItem,
        locale,
      ) as RetType;
    }
    if (retVal === undefined) {
      throw new Error(
        `Missing implementation to handle setting of complex block value type: ${schemaType}`,
      );
    }
    return addLocaleUpdates(retVal, source, locale, hasNewlyAddedFallback);
  }
  type RemainingBlockValueTypes = Exclude<
    typeof newValue._source,
    ComplexBlockValueTypes
  >;
  const source = {
    ...(newValue._source ?? newValue),
  } as RemainingBlockValueTypes;
  let hasNewlyAddedFallback = false;
  for (const key of Object.keys(newValue)) {
    const result = setPossiblyTranslatableProperty(
      newValue as TranslatedWithSource<RemainingBlockValueTypes>,
      source,
      key as keyof typeof newValue._source,
      locale,
      schemaType,
    );
    if (isNewlyAddedFallback(locale, result?.currentValue, result?.newValue)) {
      hasNewlyAddedFallback = true;
    }
  }
  delete (source as { _source?: unknown })['_source'];
  return addLocaleUpdates(
    source,
    newValue._source as RemainingBlockValueTypes,
    locale,
    hasNewlyAddedFallback,
  ) as RetType;
}
export function setBlockLocaleValue(
  newValue: string,
  oldValue: BlockLocaleValue | null | undefined,
  locale: supported_locale,
  schemaType: SchemaType,
): localeValue {
  // A little assertion to make sure we didn't do this totally wrong
  if (!stringSchemaTypeValues.includes(schemaType as StringSchemaType)) {
    throw 'Got string value for non-string schema type, this should not happen!';
  }
  const baseModelValue =
    typeof oldValue === 'string' || !oldValue
      ? { [DEFAULT_LOCALE]: oldValue ?? '' }
      : (oldValue as localeValue);
  const hasNewlyAddedFallback = isNewlyAddedFallback(
    locale,
    baseModelValue[locale],
    newValue,
  );
  return addLocaleUpdates(
    { ...baseModelValue, [locale]: newValue },
    baseModelValue,
    locale,
    hasNewlyAddedFallback,
  );
}
export function addLocaleUpdates<T extends BlockValueItemOnly>(
  source: T,
  updatesSource: BlockValueItemOnly,
  locale: supported_locale,
  hasNewlyAddedFallback: boolean,
): BlockValueWithLocaleDate<T> {
  if (hasNewlyAddedFallback) {
    // If adding a new language, we should mark all existing languages as updated to avoid them being listed as outdated
    const updatedTime = new Date();
    const retval = {
      ...source,
      localeUpdates: { [DEFAULT_LOCALE]: updatedTime, [locale]: updatedTime },
    } satisfies BlockValueWithLocaleDate<T>;
    const existingUpdates = (updatesSource as BlockValueWithLocaleDate<T>)
      ?.localeUpdates;
    for (const loc in existingUpdates) {
      retval.localeUpdates[loc as supported_locale] = updatedTime;
    }
    return retval;
  }
  return {
    ...source,
    localeUpdates: {
      ...(updatesSource as BlockValueWithLocaleDate<T>)?.localeUpdates,
      [locale]: new Date(),
    },
  };
}

export function translateStringOrLocaleWithDefault(
  value:
    | string
    | localeValue
    | undefined
    | Ref<string | localeValue | undefined>
    | ComputedRef<string | localeValue | undefined>
    | ComputedGetter<string | localeValue | undefined>,
  defaultValue: string,
): ComputedRef<string> {
  return computed(
    () => translateStringOrLocale(value).value ?? getComposer().t(defaultValue),
  );
}
export function translateStringOrLocale<
  T extends
    | string
    | localeValue
    | undefined
    | Ref<string | localeValue | undefined>
    | ComputedRef<string | localeValue | undefined>
    | ComputedGetter<string | localeValue | undefined>,
>(
  value:
    | T
    | (
        | string
        | localeValue
        | undefined
        | Ref<string | localeValue | undefined>
        | ComputedRef<string | localeValue | undefined>
        | ComputedGetter<string | localeValue | undefined>
      ),
): ComputedRef<T extends undefined ? undefined : string> {
  if (typeof value === 'function') {
    value = computed<string | localeValue | undefined>(value);
  }
  return computed(() =>
    isRef(value)
      ? translateStringOrLocale(value.value).value
      : value === undefined
        ? undefined
        : typeof value === 'string'
          ? value
          : (value as localeValue)[
              i18nInstance.global.locale.value as supported_locale
            ] ?? (value as localeValue)[DEFAULT_LOCALE],
  ) as ComputedRef<T extends undefined ? undefined : string>;
}

function isLocaleValue(value: unknown): value is localeValue {
  return (
    !!value &&
    typeof value === 'object' &&
    (DEFAULT_LOCALE in value || !!SUPPORTED_LOCALES.find((loc) => loc in value))
  );
}
