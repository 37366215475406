<script setup lang="ts">
import { useRoute } from 'vue-router';
import ContentPage from '../components/ContentPage.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const route = useRoute();
const menu = [
  { routeName: 'profile', title: 'profile.info' },
  { routeName: 'favourites', title: 'profile.favorite' },
  { routeName: 'subscriptions', title: 'profile.subscriptions' },
];
</script>

<template>
  <ContentPage :title="t('profile.title')">
    <div class="flex w-full flex-col items-center">
      <nav
        class="my-4 flex flex-wrap gap-1 p-4 after:grow-[2] after:content-['']"
        :aria-label="t('profile.tabs')"
        role="navigation"
      >
        <RouterLink
          v-for="{ routeName, title } in menu"
          :key="routeName"
          class="ring-offset-primary-blueDark grow basis-1 whitespace-nowrap rounded-lg px-4 py-2.5 text-sm font-medium text-gnist-black ring-white ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2"
          :class="[
            route.name === routeName
              ? 'cursor-default bg-gnist-green'
              : 'bg-gnist-gray-light hover:underline',
          ]"
          :to="{ name: routeName }"
        >
          {{ t(title) }}
        </RouterLink>
      </nav>
      <RouterView />
    </div>
  </ContentPage>
</template>
<style></style>
