<script setup lang="ts">
import { useRoute } from 'vue-router';
import ContentPage from '../components/ContentPage.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const route = useRoute();
const menu = [
  { routeName: 'viewCount', title: 'analytics.siteUsage' },
  { routeName: 'apiM', title: 'analytics.apiM' },
  { routeName: 'siteContent', title: 'analytics.siteContent' },
];
</script>

<template>
  <ContentPage :title="t('analytics.title')">
    <div class="flex w-full flex-col items-center">
      <nav
        class="my-4 flex w-full flex-row gap-1 p-4 md:w-1/2 md:flex-row"
        :aria-label="t('profile.tabs')"
        role="navigation"
      >
        <RouterLink
          v-for="{ routeName, title } in menu"
          :key="routeName"
          :class="[
            'grow basis-1 rounded-lg py-2.5 text-center text-sm font-medium leading-5 text-gnist-black ring-white',
            'ring-offset-primary-blueDark ring-opacity-60 ring-offset-2 focus:outline-none focus:ring-2 ',
            route.name === routeName
              ? 'bg-gnist-green underline hover:bg-gnist-green/[0.9]'
              : 'bg-gnist-gray-light hover:bg-gnist-gray-light/[0.9]',
          ]"
          :to="{ name: routeName }"
        >
          {{ t(title) }}
        </RouterLink>
      </nav>
      <RouterView />
    </div>
  </ContentPage>
</template>
<style></style>
