declare global {
  interface Window {
    env: {
      [key: string]: string;
    };
  }
}

export const frontendUrl =
  window.env?.VITE_FRONTEND_URL || import.meta.env.VITE_FRONTEND_URL || null;
/** Using `/api` as the type to simplify reading logic. It might be wrong in DEV, but not in other environments. */
export const apiUrl =
  (window.env?.VITE_API_URL as '/api') ||
  (import.meta.env.VITE_API_URL as '/api') ||
  '/api';
export const appVersion =
  window.env?.VITE_APP_VERSION || import.meta.env.VITE_APP_VERSION || null;

export const env_type: 'dev' | 'test' | 'prod' =
  import.meta.env.MODE === 'development'
    ? 'dev'
    : window.location.hostname.includes('-test')
      ? 'test'
      : 'prod';

const supported_locales = ['no', 'en'] as const;
export const SUPPORTED_LOCALES = supported_locales as Readonly<
  supported_locale[]
>;
export type supported_locale = (typeof supported_locales)[number];
export const B2C_LOCALE: { [key in supported_locale]: string } = {
  no: 'nb',
  en: 'en',
};
export const DEFAULT_LOCALE = 'no' satisfies supported_locale;

export const notFoundRouteName = 'NotFound';
export const socialPageRouteId = 'page';
export const editBlocksRouteId = 'admin';
export const editBlockVersionsRouteId = 'block_versions';
export const apimproductsRouteId = 'apimproducts';
export const mySubscriptionsRouteId = 'subscriptions';

// Should equal https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
export const twSpacingScale = [
  '0',
  'px',
  '0.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '20',
  '24',
  '28',
  '32',
  '36',
  '40',
  '44',
  '48',
  '52',
  '56',
  '60',
  '64',
  '72',
  '80',
  '96',
] as const;
export const twSpacingNamedScale = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
  '7xl',
  'screen-sm',
  'screen-md',
  'screen-lg',
  'screen-xl',
] as const;
/** Can only be used for max-width */
export const twSpacingMaxWidthOnlyScale = ['prose'] as const;
