import { apiUrl } from '@/config';
import type { Ref } from 'vue';
import type { Guid, OrganizationData, OrganizationUser } from './types';
import {
  compose,
  type ComposableResult,
  composeWithRefresh,
  type ComposableResultWithRefresh,
} from './composable';
import { throwErrorPageTrigger } from '@/utilities/throwErrorPageTrigger';

const orgUrl = `${apiUrl}/organization`;

export function useOrganizations(
  includeAll = false,
): ComposableResult<OrganizationData[]> {
  return compose(getOrganizations(includeAll));
}
export const getOrganizations = async (
  includeAll: boolean,
): Promise<OrganizationData[]> => {
  const response = await fetch(`${orgUrl}?includeAll=${includeAll}`, {
    method: 'GET',
    credentials: 'include',
  });
  throwErrorPageTrigger(response);
  if (!response.ok) throw new Error('error.get_organizations_failed');
  const data: OrganizationData[] = await response.json();
  return data.sort((a, b) => a.name.localeCompare(b.name));
};

async function getOrganization(orgId: string, throwForErrorPages = false) {
  const r = await fetch(`${orgUrl}/${orgId}`);
  if (throwForErrorPages) {
    throwErrorPageTrigger(r);
  }
  try {
    return r.ok ? await r.json() : [];
  } catch {
    return null;
  }
}

export function useOrganization(
  orgId: string | Ref<string | undefined>,
  throwForErrorPages = false,
): ComposableResultWithRefresh<OrganizationData | null> {
  const refresh = async () =>
    typeof orgId === 'string'
      ? getOrganization(orgId, throwForErrorPages)
      : orgId.value === undefined
        ? null
        : getOrganization(orgId.value, throwForErrorPages);
  return composeWithRefresh(
    refresh,
    typeof orgId === 'string' ? undefined : orgId,
  );
}

export async function createOrganization(
  organizationData: OrganizationData,
): Promise<{ organizationId: Guid }> {
  const response = await fetch(orgUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(organizationData),
  });
  if (!response.ok) throw new Error('error.create_org_failed');

  return await response.json();
}

export async function updateOrganization(
  organizationData: OrganizationData,
): Promise<OrganizationData> {
  const response = await fetch(`${orgUrl}/${organizationData.organizationId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(organizationData),
  });
  if (!response.ok) {
    const text = await response.text();
    if (text.includes('CONSTRAINT_VIOLATION')) {
      throw new Error('error.org_constraint_violation');
    }
    throw new Error('error.save_org_failed');
  }

  return await response.json();
}

export async function addUserToOrganization(
  organizationId: Guid,
  email: string,
): Promise<OrganizationUser[]> {
  const response = await fetch(`${orgUrl}/${organizationId}/users`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ email: email }),
  });
  if (!response.ok) {
    const responseText = await response.text();
    if (responseText.includes('Could not find user')) {
      throw new Error('organization.userNotFound');
    } else if (responseText.includes('User already part of organization')) {
      throw new Error('organization.userAlreadyPartOfOrganization');
    }
    console.error(responseText);
    throw new Error('organization.unknownAddUserError');
  }

  return await response.json();
}

export async function requestMembership(
  organizationId: Guid,
  email: string,
  cancel = false,
): Promise<OrganizationUser[]> {
  const cancelQuery = cancel ? '?cancel=true' : '';
  const response = await fetch(
    `${orgUrl}/${organizationId}/requestMembership${cancelQuery}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ email: email }),
    },
  );
  if (!response.ok) {
    if (cancel) throw new Error('organization.cancelRequestMembershipError');
    else throw new Error('organization.requestMembershipError');
  }
  return await response.json();
}

export async function acceptMembershipRequest(
  organizationId: Guid,
  requestedUser: OrganizationUser,
): Promise<OrganizationUser[]> {
  const response = await fetch(
    `${orgUrl}/${organizationId}/approveMembership`,
    {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(requestedUser),
    },
  );
  if (!response.ok) {
    const responseText = await response.text();
    if (responseText.includes('Could not find user')) {
      throw new Error('organization.userNotFound');
    } else if (responseText.includes('User already part of organization')) {
      throw new Error('organization.userAlreadyPartOfOrganization');
    }
    console.error(responseText);
    throw new Error('organization.unknownAddUserError');
  }

  return await response.json();
}

export async function removeUserFromOrganization(
  organizationId: Guid,
  userId: string,
): Promise<OrganizationUser[]> {
  const response = await fetch(`${orgUrl}/${organizationId}/users/${userId}`, {
    method: 'DELETE',
    credentials: 'include',
  });
  if (!response.ok) throw new Error('error.removeUserFailed');

  return await response.json();
}
