<script setup lang="ts">
import { ref, watch } from 'vue';
import MaterialIcon from './MaterialIcon.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import {
  getConnectedListBySearchId,
  type ConnectionInfo,
} from './BlockListConnectionHelper';

const { t } = useI18n();

const props = defineProps<{
  initialValue?: string;
  /** If supplied, the search bar will start the search itself */
  targetRoute?: string;
  connection?: ConnectionInfo;
}>();

const emit = defineEmits<{
  (e: 'change', input: string): void;
  (e: 'submit', input: string): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>();

const input = ref<HTMLInputElement | null>(null);
const router = useRouter();
const search = () => {
  if (!props.targetRoute) {
    emit('submit', input.value?.value ?? '');
  } else {
    router.push({
      name: props.targetRoute,
      query: { q: input.value?.value ?? '' },
    });
  }
};

const searchText = ref(props.initialValue ?? '');
watch(searchText, () => {
  emit('change', searchText.value ?? '');
  if (props.connection?.role !== 'search') return;
  getConnectedListBySearchId(props.connection)?.setSearchText(searchText.value);
});
</script>

<template>
  <form
    role="search"
    class="flex h-12 items-center justify-stretch rounded-full bg-gnist-white pr-4 shadow-md"
    data-cy-id="SearchForm"
    @submit.prevent="search"
  >
    <MaterialIcon class="px-5 text-2xl"> search </MaterialIcon>
    <label class="sr-only" for="search">{{ t('blockList.search') }}</label>
    <input
      id="search"
      ref="input"
      v-model="searchText"
      :title="t('blockList.searchTitle')"
      name="search"
      type="search"
      :placeholder="t('blockList.search')"
      class="mr-2 min-w-0 grow outline-none"
      data-cy-id="SearchBar"
      @focus="emit('focus')"
      @blur="emit('blur')"
    />
  </form>
</template>
