<script lang="ts" setup>
import ContentPage from '../components/ContentPage.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EditOrganizationComponent from '@/components/EditOrganizationComponent.vue';
import { computed, ref } from 'vue';
import { requestMembership, useOrganizations } from '@/api/organization';
import { EMPTY_GUID, type OrganizationData } from '@/api/types';
import { useRouter } from 'vue-router';
import ModalComponent from '@/components/ModalComponent.vue';
import { useI18n } from 'vue-i18n';
import { useCurrentUser } from '@/api/auth';
import { useStoreAbility } from '@/abilities';
import { throwOnError } from '@/api/composable';
import TableComponent from '@/components/TableComponent.vue';

const { t } = useI18n();
const { can } = useStoreAbility();

const showModal = ref(false);
const router = useRouter();

const userInfo = useCurrentUser();
const userType = computed(() =>
  can('update', 'Organization')
    ? 'admin'
    : userInfo.value?.roles?.includes('internal')
      ? 'internal'
      : 'external',
);

const { result: organizationData } = throwOnError(useOrganizations(true));

const filteredOrganizationData = computed(() =>
  organizationData.value?.filter(
    (org) =>
      userType.value === 'admin' ||
      userInfo.value?.memberOrgs?.includes(org.organizationId) ||
      userInfo.value?.requestedOrgs?.includes(org.organizationId),
  ),
);

const handleModalClick = async (organizationData: OrganizationData) => {
  await router.push({
    name: 'organization',
    params: {
      organizationId: organizationData.organizationId,
    },
  });
  window.location.reload();
};

async function cancelRequestMembershipOfOrg(organization: OrganizationData) {
  if (!userInfo.value?.email) return;
  await requestMembership(
    organization.organizationId,
    userInfo.value.email,
    true,
  );
  userInfo.value.requestedOrgs = userInfo.value?.requestedOrgs?.filter(
    (o) => o !== organization.organizationId,
  );
}
</script>
<template>
  <ContentPage
    :title="t(`organization.listHeader.${userType}`)"
    class="bg-gnist-white"
  >
    <div class="pb-8 lg:px-8">
      <TableComponent
        size="table-lg"
        :rows="filteredOrganizationData"
        show-spinner
        i18n-key="organization"
        :get-key="(organization) => organization.organizationId"
        :columns="['name']"
        no-header
        class="mb-8 rounded border"
      >
        <template #columns="{ item: organization }">
          <td
            v-if="
              userInfo?.memberOrgs?.includes(organization.organizationId) ||
              userType === 'admin'
            "
            class="flex items-center justify-between !py-0"
          >
            <RouterLink
              class="w-full py-4"
              :to="{
                name: 'organization',
                params: { organizationId: organization.organizationId },
              }"
            >
              {{ organization.name }}
            </RouterLink>
            <p
              v-if="userInfo?.memberOrgs?.includes(organization.organizationId)"
              class="badge badge-outline badge-lg cursor-default truncate border-gnist-gray bg-gnist-purple"
            >
              {{ t('organization.isMember') }}
            </p>
          </td>
          <td v-else class="flex justify-between">
            {{ organization.name }}
            <div class="flex items-center gap-4">
              <p
                class="badge badge-outline badge-lg border-gnist-gray bg-gnist-orange"
              >
                {{ t('organization.requestedMembership') }}
              </p>
              <ButtonComponent
                :text="t('organization.cancelRequestMembership')"
                @click="cancelRequestMembershipOfOrg(organization)"
              />
            </div>
          </td>
        </template>
      </TableComponent>

      <ButtonComponent
        class=""
        :text="
          userType === 'admin'
            ? t('organization.registerAdmin')
            : userType === 'internal'
              ? t('organization.registerDepartment')
              : t('organization.register')
        "
        type="primary"
        :onclick="() => (showModal = true)"
      />
      <ModalComponent
        :title="
          userType === 'admin'
            ? t('organization.registerAdmin')
            : userType === 'internal'
              ? t('organization.registerDepartment')
              : t('organization.register')
        "
        size="full"
        :show-modal="showModal"
        no-scroll
        @close-modal="() => (showModal = false)"
      >
        <template #default>
          <EditOrganizationComponent
            is-create
            :data="{
              organizationId: EMPTY_GUID,
              name: '',
              webpage: '',
              logo: '',
              users: [],
              requestedUsers: [],
              blocks: [],
              isInternal: userType !== 'external',
            }"
            :all-orgs="organizationData"
            @on-saved="handleModalClick"
            @close-modal="() => (showModal = false)"
          />
        </template>
        <template #buttons>
          <div />
        </template>
      </ModalComponent>
    </div>
  </ContentPage>
</template>

<style scoped>
:deep(th),
:deep(td) {
  text-align: left;
  padding: 1rem 0.5rem;
  text-overflow: ellipsis;
}
</style>
