<script lang="ts" setup>
import {
  routeTypes,
  type routeType,
  type RouteItemDTO,
  fgColorList,
  type Translated,
  type Link,
  type PartialSome,
  type Guid,
} from '@/api/types';
import { computed, toRef } from 'vue';
import TextInput from '@/components/forms/InputText.vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import LinkInput from '@/components/forms/InputLink.vue';
import { mapValueList } from '@/utilities/mapValueList';
import { useInternalState } from '@/utilities/useInternalState';
import { useRouter } from 'vue-router';
import { getBlocksRouteLink } from '@/utilities/routeUtils';

const props = defineProps<{
  modelValue?: PartialSome<RouteItemDTO, 'id'> | null;
  allItems?: RouteItemDTO[];
}>();
type ModelType = typeof props.modelValue;
const emit = defineEmits<{
  (e: 'update:modelValue', value: ModelType): void;
  (e: 'reportValidationError', name: string, hasError: boolean): void;
}>();

const router = useRouter();

type internalValType = Required<Pick<NonNullable<ModelType>, 'customOptions'>> &
  Omit<NonNullable<ModelType>, 'aliases' | 'routeType' | 'targetDoc'> & {
    aliases: string;
    routeType: routeType | null;
    targetDoc: PartialSome<Translated<Link>, 'text' | 'alt'> | undefined;
    targetRoute: Pick<Translated<Link>, 'url'> | undefined;
  };
const item = useInternalState<ModelType, internalValType | null>(
  toRef(props, 'modelValue'),
  null,
  (val) => emit('update:modelValue', val),
  (newExtState) => {
    if (newExtState == null) return null;
    return {
      ...newExtState,
      aliases: newExtState.aliases?.join(',') ?? '',
      routeType: newExtState.routeName === null ? null : newExtState.routeType,
      customOptions: newExtState.customOptions ?? {},
      targetDoc:
        newExtState.targetDoc === undefined
          ? undefined
          : {
              url: router.resolve(getBlocksRouteLink(newExtState.targetDoc))
                .fullPath,
            },
      targetRoute:
        newExtState.routeType === 'hardcoded'
          ? {
              url: newExtState.routeName
                ? router.resolve({ name: newExtState.routeName }).fullPath
                : '',
            }
          : undefined,
    } satisfies internalValType;
  },
  (newIntState) => {
    if (newIntState == null) return null;
    return {
      ...newIntState,
      routeName: newIntState.targetRoute?.url
        ? router.resolve(newIntState.targetRoute.url).name?.toString() ??
          newIntState.targetRoute.url
        : newIntState.routeName === null
          ? newIntState.routeType === null
            ? (null as unknown as string)
            : ''
          : newIntState.routeName,
      aliases: newIntState.aliases.split(',').map((i: string) => i.trim()),
      targetDoc:
        newIntState.routeType !== 'page'
          ? undefined
          : !newIntState.targetDoc?.url
            ? ('-' as Guid)
            : (router
                .resolve(newIntState.targetDoc.url)
                .params.blockId.toString() as Guid),
      headerType:
        newIntState.routeType == 'header'
          ? newIntState.headerType ?? 'menu'
          : undefined,
      routeType: newIntState.routeType ?? 'hardcoded',
      customOptions:
        newIntState.routeType === 'page'
          ? newIntState.customOptions
          : undefined,
    } satisfies ModelType;
  },
);

const routeTypeOptions = mapValueList(
  routeTypes,
  'routeAdmin.routeType',
  true,
  null,
);
const targetMenuOptions = mapValueList(['main'], 'routeAdmin.targetMenu', true);
const headerTypeOptions = mapValueList(
  ['menu', 'routeOnly', 'menuOnly'],
  'routeAdmin.headerType',
);
const parentOptions = computed(() =>
  toDropdownItems(
    props.allItems?.filter((i) => !!i.headerType),
    (i) => [i.routeName, i.text.no],
    true,
  ),
);
const colorOptions = mapValueList(fgColorList, 'routeAdmin.colors', true);
</script>
<template>
  <div v-if="item">
    <div class="mt-12 flex flex-col gap-8 [&_.GnistInputLabel]:font-bold">
      <div
        class="flex grow flex-col gap-8 md:grid md:grid-cols-[repeat(auto-fit,minmax(max(15rem,30%),1fr))]"
      >
        <Dropdown
          v-model="item.routeType"
          label="routeAdmin.routeType.title"
          tooltip="routeAdmin.routeType.tooltip"
          class="RouteType"
          allow-empty-value
          disable-empty-option
          :options="routeTypeOptions"
          :getkey="(val) => val"
          required
        />
        <template v-if="item.routeType">
          <TextInput
            v-if="item.routeType !== 'hardcoded'"
            v-model="item.routeName"
            label="routeAdmin.routeName"
            tooltip="routeAdmin.routeNameTooltip"
            required
          />
          <LinkInput
            v-else
            v-model="item.targetRoute"
            for-name="routeName"
            label="routeAdmin.routeNameStatic"
            tooltip="routeAdmin.routeNameStaticTooltip"
            inline
            hide-text
            hide-description
            show-validation-message
            can-target-hardcoded
            required
          />
          <Dropdown
            v-if="item.routeType === 'header'"
            v-model="item.headerType"
            label="routeAdmin.headerType.title"
            :options="headerTypeOptions"
            :getkey="(val) => val ?? 'empty'"
          />
          <LinkInput
            v-if="item.routeType == 'page'"
            v-model="item.targetDoc"
            for-name="targetdoc"
            label="routeAdmin.targetDoc"
            inline
            hide-text
            hide-description
            show-validation-message
            can-target-blocks
            required
          />
          <Dropdown
            v-model="item.targetMenu"
            label="routeAdmin.targetMenu.title"
            tooltip="routeAdmin.targetMenu.tooltip"
            :options="targetMenuOptions"
            :getkey="(val) => val ?? 'empty'"
          />
          <Dropdown
            v-model="item.parent"
            label="routeAdmin.parent"
            tooltip="routeAdmin.parentTooltip"
            class="ParentField"
            :options="parentOptions"
            :getkey="(val) => val ?? 'empty'"
          />
          <Checkbox v-model="item.mobileOnly" label="routeAdmin.mobileOnly" />
          <TextInput
            v-if="item.routeType !== 'header'"
            v-model="item.aliases"
            label="routeAdmin.aliases"
            tooltip="routeAdmin.aliasesTooltip"
          />
          <div
            v-if="!(item.headerType === 'routeOnly') && !!item.targetMenu"
            class="col-span-2 grid grid-cols-2 gap-8"
          >
            <TextInput
              v-model="item.text.no"
              label="routeAdmin.textNo"
              required
            />
            <TextInput
              v-model="item.text.en"
              label="routeAdmin.textEn"
              required
            />
          </div>
          <TextInput
            v-model="item.sortOrder"
            label="routeAdmin.sortOrder"
            tooltip="routeAdmin.sortOrderTooltip"
            type="number"
          />
          <Checkbox
            v-if="item.routeType === 'page'"
            v-model="item.isDocsListRoute"
            label="routeAdmin.isDocsRoute"
            tooltip="routeAdmin.isDocsRouteTooltip"
          />
        </template>
      </div>
      <div
        v-if="item.routeType === 'page'"
        class="grid grid-cols-1 gap-8 md:grid-cols-3"
      >
        <Dropdown
          v-model="item.customOptions.fgColor"
          label="routeAdmin.colors.title"
          :options="colorOptions"
          :getkey="(val) => val ?? 'empty'"
        />
        <Checkbox
          v-model="item.customOptions.fullscreen"
          label="routeAdmin.fullscreen"
        />
        <Checkbox
          v-model="item.customOptions.hideShimmer"
          label="routeAdmin.hideShimmer"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.GnistInputLabel) .tooltip:before {
  @apply sm:!w-max;
  width: calc(100vw - 2rem);
}
:deep(.ParentField) .tooltip:before,
:deep(.RouteType) .tooltip:before {
  @apply max-w-lg;
  @apply text-left;
}
</style>
