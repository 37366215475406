<script lang="ts" setup>
import { getEmptyLocaleValue, type Category } from '@/api/types';
import ContentPage from '@/components/ContentPage.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CategoryEditor from '@/components/CategoryEditor.vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { throwOnError } from '@/api/composable';
import {
  createCategory,
  deleteCategory,
  updateCategory,
  useCategoriesLazy,
} from '@/api/category';
import { translateStringOrLocale } from '@/i18n';
import { useValidationTracking } from '@/utilities/useValidationTracking';
import { defaultSchema } from '@/components/SchemaEditor.vue';
import TableComponent from '@/components/TableComponent.vue';

const { result: categories } = throwOnError(useCategoriesLazy());

const selectedItem = ref<Category | null>(null);
const showDetailsModal = ref(false);

function viewCategoryDetails(category: Category, ev?: MouseEvent) {
  if (ev?.defaultPrevented) return;
  selectedItem.value = category;
  showDetailsModal.value = true;
}

function closeCategoryDetails() {
  showDetailsModal.value = false;
  // Delay resetting the selected user to avoid the user information disappearing from
  // the dialog before it's closed
  setTimeout(() => (selectedItem.value = null), 200);
}

async function onSave() {
  if (!selectedItem.value) return;
  if (!isValid.value) {
    showValidationMessages.value = true;
    return;
  }
  showValidationMessages.value = false;
  const saveValue: Category = {
    ...selectedItem.value,
    schema: selectedItem.value.schema?.map((s) => ({
      ...s,
      requiredByTemplate: undefined,
      canUnsetRequired: undefined,
    })),
  };
  if (!selectedItem.value.categoryId) {
    const updatedItem = await createCategory(saveValue);
    selectedItem.value.categoryId = updatedItem.categoryId;
  } else {
    await updateCategory(saveValue);
  }
  closeCategoryDetails();
}

function onCreateButtonClick() {
  if (!categories.value) return;
  const newItem: Omit<Category, 'categoryId'> = {
    name: getEmptyLocaleValue(),
    schema: [{ ...defaultSchema, name: 'content' }],
  };
  categories.value.push(newItem as Category);
  viewCategoryDetails(newItem as Category);
}

const showDeleteModal = ref(false);
const itemToDelete = ref<Category>();
async function doDelete() {
  if (!itemToDelete.value?.categoryId) return;
  await deleteCategory(itemToDelete.value.categoryId.toString());
  showDeleteModal.value = false;
  categories.value =
    categories.value?.filter(
      (i) => i.categoryId !== itemToDelete.value?.categoryId,
    ) ?? [];
  itemToDelete.value = undefined;
}

const { t } = useI18n();

const showValidationMessages = ref<boolean>(false);
const { onValidChanged, formRef, isValid } = useValidationTracking(
  selectedItem,
  showValidationMessages,
  true,
);
</script>
<template>
  <ContentPage
    :title="t('categoryAdmin.title')"
    class="bg-gnist-gray-light-light"
  >
    <ModalComponent
      :show-modal="showDetailsModal"
      :title="t('categoryAdmin.view')"
      size="big"
      @close="closeCategoryDetails"
    >
      <template #default>
        <form ref="formRef">
          <CategoryEditor
            v-model="selectedItem"
            :all-items="categories ?? []"
            @report-validation-error="
              (name, hasError) => onValidChanged(name, !hasError)
            "
          />
        </form>
      </template>
      <template #buttons>
        <div className="flex justify-end">
          <div class="gnist-button-group">
            <ButtonComponent
              :text="t('buttons.cancel')"
              @click="closeCategoryDetails"
            />
            <ButtonComponent
              type="primary"
              :text="t('buttons.save')"
              :disabled="!isValid"
              @click="onSave"
            />
          </div>
        </div>
      </template>
    </ModalComponent>

    <div class="pb-8">
      <div class="w-full max-w-full overflow-x-auto">
        <ButtonComponent
          :text="t('categoryAdmin.create')"
          @click="onCreateButtonClick"
        />
        <RouterLink
          :to="{ path: '/usermanual/about_categories' }"
          target="_blank"
          class="mx-4 underline hover:bg-gnist-gray-light"
        >
          {{ t('categoryAdmin.help') }}
        </RouterLink>
        <TableComponent
          size="table-lg"
          :rows="categories"
          show-spinner
          i18n-key="categoryAdmin"
          :get-key="(category) => category.categoryId"
          :columns="['name', 'sortOrder', null]"
          row-class="cursor-pointer"
          @click:row="(category, ev) => viewCategoryDetails(category, ev)"
        >
          <template #columnHeader="{ item: category }">
            {{ translateStringOrLocale(category.name).value }}
          </template>
          <template #columns="{ item: category }">
            <td>
              {{ category.sortOrder }}
            </td>
            <td class="ButtonRow cursor-default" @click.stop>
              <ButtonComponent
                :text="t('categoryAdmin.delete.button')"
                class="gnist-button gnist-button-danger"
                @click.prevent="
                  (ev) => {
                    ev.preventDefault();
                    itemToDelete = category;
                    showDeleteModal = true;
                  }
                "
              />
            </td>
          </template>
        </TableComponent>

        <ModalComponent
          :show-modal="showDeleteModal"
          :title="t('categoryAdmin.delete.title')"
          @close="
            () => {
              itemToDelete = undefined;
              showDeleteModal = false;
            }
          "
          @handle-click="doDelete"
        >
          <template #default>
            <p class="py-2">
              <strong>{{ t('categoryAdmin.name') }}: </strong>
              {{ translateStringOrLocale(itemToDelete?.name).value }}
            </p>
            <p>{{ t('categoryAdmin.delete.warning') }}</p>
          </template>
        </ModalComponent>
      </div>
    </div>
  </ContentPage>
</template>

<style scoped>
:deep(th),
:deep(td:not(.ButtonRow)) {
  text-align: left;
  padding: 1rem 0.5rem;
  text-overflow: ellipsis;
}
</style>
