<script setup lang="ts">
import ContentPage from '@/components/ContentPage.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import TagEditor from '@/components/TagEditor.vue';
import { useI18n } from 'vue-i18n';
import {
  createTag,
  deleteTag,
  updateTag,
  useTagUsages,
  useTags,
} from '@/api/tags';
import {
  removeParentKey,
  type KeyOfRecursive,
  type KeyOfRecursiveBelow,
  type Tag,
} from '@/api/types';
import { SUPPORTED_LOCALES, type supported_locale } from '@/config';
import { computed, ref } from 'vue';
import TableComponent from '@/components/TableComponent.vue';

const { t, locale } = useI18n();

const { result: tags } = useTags();
const { result: tagUsages } = useTagUsages();

const tagEditors = ref<InstanceType<typeof TagEditor>[]>([]);

const changeList = ref<{ [id: number]: boolean }>({});
const hasChanges = computed(
  () =>
    Object.values(changeList.value).includes(true) ||
    removedTags.value.length > 0,
);

async function save() {
  if (!hasChanges.value) return;
  if (!tags.value) return;
  for (const [id, hasChange] of Object.entries(changeList.value)) {
    if (!hasChange) continue;
    const tagToSave = tags.value.find((u) => `${u.id}` === id);
    if (!tagToSave) throw new Error('Should not happen');
    if (tagToSave.id === -1) {
      const newTag = await createTag(tagToSave);
      tagToSave.id = newTag.id;
    } else {
      await updateTag(tagToSave);
    }
  }
  changeList.value = {};
  for (const tagToDelete of removedTags.value) {
    await deleteTag(tagToDelete);
  }
  removedTags.value.length = 0;
  tagEditors.value?.forEach((e) => e.resetSource());
}

const textPropPrefix: `${keyof Pick<Tag, 'text'>}.` = 'text.';
const locales = [...SUPPORTED_LOCALES]
  .sort((a, b) => (a === locale.value ? -1 : b === locale.value ? 1 : 0))
  .map((i): KeyOfRecursiveBelow<Tag, 'text'> => `${textPropPrefix}${i}`);

const columns = computed(() => {
  const cols: (KeyOfRecursive<Tag> | null)[] = [...locales];
  cols.push('usages');
  cols.push('primaryUsageId');
  cols.push('homePageId');
  cols.push(null);
  return cols;
});

function getColumnHeader(column: KeyOfRecursive<Tag>) {
  if (!locales.includes(column as KeyOfRecursiveBelow<Tag, 'text'>)) {
    return t(`tagEditor.columns.${column}`);
  } else {
    return (
      t(`language.values.${removeParentKey(column, 'text')}`) +
      t('tagEditor.columns.text')
    );
  }
}
const quickEdit = ref(false);

const removedTags = ref<number[]>([]);
function removeTag(tagId: number) {
  if (!tags.value) return;
  removedTags.value.push(tagId);
  tags.value = tags.value.filter((t) => t.id !== tagId);
}
</script>

<template>
  <ContentPage :title="t('tagEditor.title')" class="bg-gnist-gray-light-light">
    <section class="py-8">
      <div class="w-full max-w-full">
        <div class="my-6 flex justify-between">
          <Checkbox
            v-model="quickEdit"
            label="tagEditor.quickEdit"
            direction="horizontal"
            mode="toggle"
            class="grow-0"
          />
          <ButtonComponent
            :text="t('tagEditor.save')"
            type="primary"
            :disabled="!hasChanges"
            @click="() => save()"
          />
        </div>
        <TableComponent
          size="table-xs"
          :rows="tags"
          show-spinner
          i18n-key="tagEditor.columns"
          :get-key="(tag) => tag.id"
          :columns="columns"
          :get-column-header="getColumnHeader"
          :sort-fields="locales as KeyOfRecursive<Tag>[]"
          :default-sortfield="`text.${locale as supported_locale}`"
          skip-sort-on-content-change
        >
          <template #columns="{ item: tag, rowIndex: idx, items }">
            <TagEditor
              :ref="
                (ref) => tagEditors.push(ref as InstanceType<typeof TagEditor>)
              "
              v-model="items[idx]"
              :locale-keys="locales"
              :quick-edit="quickEdit"
              :usages="tagUsages ?? []"
              @update:changed="(val) => (changeList[tag.id] = val)"
              @remove="removeTag(tag.id)"
            />
          </template>
        </TableComponent>
      </div>
    </section>
  </ContentPage>
</template>
