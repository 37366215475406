<script setup lang="ts">
import type { ApiMetaData, BlockApimDetails } from '@/api/types';
import { useApiList } from '@/api/apiM';
import ButtonComponent from '../ButtonComponent.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import InputLabel, {
  type IInputLabelProps,
} from '@/components/forms/InputLabel.vue';
import InputText from '@/components/forms/InputText.vue';
import { useI18n } from 'vue-i18n';
import { computed, toRef } from 'vue';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import { useNestedMV } from '@/utilities/useInternalState';
import { useStoreAbility } from '@/abilities';

const { t } = useI18n();
const { can } = useStoreAbility();

const props = defineProps<
  IInputLabelProps & {
    modelValue: BlockApimDetails | undefined;
    isNewBlock?: boolean;
    mode: 'Block' | 'Version';
  }
>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: BlockApimDetails | undefined): void;
  (e: 'readMetadata', value: ApiMetaData): void;
}>();

const value = useNestedMV(
  props,
  (val) => emit('update:modelValue', val),
  (stateReplaced, newVal, oldVal) => {
    const stateChangedToFromUndefined =
      (newVal === undefined && oldVal !== undefined) ||
      (newVal !== undefined && oldVal === undefined);
    return !stateReplaced || stateChangedToFromUndefined
      ? 'applyUpdate'
      : 'skipUpdate';
  },
);

const canEdit = can('update', 'ApiConnection');

const { result: apiListGrouped } = useApiList(
  canEdit ? undefined : toRef(props, 'modelValue'),
);
const apiOptions = computed(() => {
  return toDropdownItems(
    apiListGrouped.value?.map((i) => i.versions).flat() ?? [],
    (api) => [
      api.name,
      api.displayName +
        (api.apiVersion
          ? ` (${t('block.apiVersion')}: ${api.apiVersion})`
          : ''),
      api,
    ],
  );
});
const apimValue = computed({
  get() {
    return apiOptions.value?.find((i) => i.key === value.value?.apimId)?.value;
  },
  set(newValue) {
    if (!value.value) return;
    value.value.apimId = newValue?.name;
  },
});
</script>
<template>
  <fieldset
    class="ApimFieldset flex min-w-0 flex-col items-stretch gap-x-4 border p-4 ps-4 md:grid md:grid-cols-[minmax(max-content,calc(50%_-_0.5rem))_fit-content(25%)_fit-content(25%)]"
    :data-is-block-field="isBlockField ? true : undefined"
  >
    <legend>{{ t(label ?? 'admin.blockProduction.apimLegend') }}</legend>
    <Checkbox
      :model-value="!!value"
      :label="`admin.blockProduction.useApimLabel${mode}`"
      direction="horizontal"
      mode="toggle"
      class="col-span-3"
      :disabled="!canEdit"
      @update:model-value="
        (checked) => (value = checked ? { apimId: undefined } : undefined)
      "
    />
    <template v-if="value">
      <Dropdown
        v-if="canEdit"
        v-model="apimValue"
        label="admin.blockProduction.selectApi"
        tooltip="admin.blockProduction.selectApiTooltip"
        :options="apiOptions"
        placeholder="admin.blockProduction.selectApiPlaceholder"
        :getkey="(o) => o?.name"
        required
      />
      <InputText
        v-else
        label="admin.blockProduction.selectedApi"
        :model-value="apimValue?.displayName ?? ''"
        disabled
      />
      <div
        v-if="mode === 'Block' && canEdit"
        class="flex flex-row-reverse items-center justify-end md:flex-col md:items-start"
      >
        <div class="md:mx-10">
          <InputLabel
            label="&nbsp;"
            :tooltip="t('admin.blockProduction.useApiMetadataTooltip')"
          />
        </div>
        <ButtonComponent
          :text="t('admin.blockProduction.useApiMetadata')"
          class="mx-4 my-2 py-2"
          type="primary"
          @click="
            () => {
              if (apimValue) emit('readMetadata', apimValue);
            }
          "
        />
      </div>
      <Checkbox
        v-model="value.useAuthentication"
        label="admin.blockProduction.apiMBlockUseAuthentication"
        checkbox-class="ml-2"
        :disabled="!canEdit"
      />
    </template>
    <p v-if="!canEdit" class="alert alert-warning col-span-3">
      {{ t('admin.blockProduction.noApimAccess') }}
    </p>
  </fieldset>
</template>

<style>
.BlockField:has(.ApimFieldset.ApimFieldset[data-is-block-field]) {
  @apply pt-0;
}
.BlockField:has(.ApimFieldset.ApimFieldset[data-is-block-field])
  .DeleteWarning {
  @apply top-6;
}
</style>
