<script setup lang="ts">
import { computed, ref } from 'vue';
import type { Tag, TagUsage } from '@/api/types';
import { useCategoriesLazy, useCategoryHomePageLink } from '@/api/category';
import { useTagUsage, useTags } from '@/api/tags';
import { watch } from 'vue';
import TagRenderer from './TagRenderer.vue';
import { getBlocksRouteLink } from '@/utilities/routeUtils';
import type { RouteLocation } from 'vue-router';
import { useRouter } from 'vue-router';
import ButtonComponent from './ButtonComponent.vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  list: TagUsage['id'] | 'categories';
  maxItems: number;
}>();

const { t } = useI18n();

const usageId = computed(() =>
  props.list === 'categories' ? undefined : props.list,
);
const { result: usage } = useTagUsage(usageId);
const { result: tags, init: loadTags } = useTags(usageId, true);
const { result: categories, init: loadCategories } = useCategoriesLazy(true);
watch(
  () => props.list,
  () => {
    if (props.list === 'categories') loadCategories();
    else loadTags();
  },
  { immediate: true },
);

const router = useRouter();
const _items = computed(
  (): (Pick<Tag, 'text' | 'color'> & { link: RouteLocation })[] | undefined => {
    if (props.list === 'categories') {
      return categories.value?.map((c) => ({
        text: c.name,
        color: c.color,
        link: useCategoryHomePageLink(c)!,
      }));
    } else {
      return tags.value?.map((t) => ({
        text: t.text,
        link: router.resolve(getBlocksRouteLink(t.homePageId, { tags: t.id })),
      }));
    }
  },
);
const showAll = ref(false);
const items = computed(() =>
  showAll.value ? _items.value : _items.value?.slice(0, props.maxItems),
);
</script>

<template>
  <div class="m-8 flex flex-col">
    <div class="flex flex-wrap gap-4">
      <RouterLink v-for="item in items" :key="item.text.no" :to="item.link">
        <TagRenderer
          :tags="[item]"
          size="badge-lg"
          :color="usage?.color ?? item.color"
        />
      </RouterLink>
    </div>
    <ButtonComponent
      v-if="_items && _items?.length > maxItems"
      :text="t(showAll ? 'tagList.less' : 'tagList.more')"
      class="self-end"
      @click="showAll = !showAll"
    />
  </div>
</template>
