import { AbilityBuilder, createMongoAbility, subject } from '@casl/ability';
import { useAbility } from '@casl/vue';
import type { ForcedSubject, PureAbility } from '@casl/ability';
import type { CurrentUser } from '@/api/types';

export type Action =
  | 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'publish'
  | 'manage'
  | 'own'
  | 'import_export';
export type Subject =
  | 'Block'
  | 'CustomBlock'
  | 'BlockFromRoute'
  | 'News'
  | 'Comment'
  | 'Discussion'
  | 'User'
  | 'Analytics'
  | 'Organization'
  | 'Route'
  | 'Category'
  | 'Suggestion'
  | 'MyDocumentsToPublish'
  | 'ProducerTools'
  | 'AdminTools'
  | 'Bug'
  | 'ApiConnection'
  | 'Tags';

type StoreAbility = PureAbility<[Action, Subject | ForcedSubject<Subject>]>;

export function defineAbilities(
  user: Pick<CurrentUser, 'id' | 'roles' | 'memberOrgs'> | null,
): StoreAbility {
  const { can, build } = new AbilityBuilder<StoreAbility>(createMongoAbility);

  if (user?.roles?.includes('administrator')) {
    can('read', 'AdminTools');
    can('create', 'News');
    can('update', 'Discussion');
    can('delete', 'Comment');
    can('read', 'Analytics');
    can('create', 'Block');
    can('create', 'CustomBlock');
    can('update', 'Block');
    can('delete', 'Block');
    can('import_export', 'Block');
    // This should follow rules in DocumentPublishRightsRequirement.cs
    can('publish', 'Block');
    can('read', 'Organization');
    can('update', 'User');
    can('update', 'Organization');
    can('update', 'Route');
    can('update', 'Category');
    can('update', 'Suggestion');
    can('delete', 'Suggestion');
    can('update', 'Tags');
  }
  if (user?.roles?.includes('moderator')) {
    can('read', 'ProducerTools');
    can('create', 'News');
    can('update', 'Discussion');
    can('delete', 'Comment');
    can('read', 'Analytics');
  }
  if (user?.roles?.includes('editor')) {
    can('read', 'ProducerTools');
    can('create', 'Block');
    can('update', 'Block');
    // This should follow rules in DocumentPublishRightsRequirement.cs
    can('publish', 'Block');
    can('read', 'Organization');
    can('manage', 'Block');
    can('update', 'Tags');
  }
  if (user?.roles?.includes('orgmember')) {
    can('read', 'ProducerTools');
    can('create', 'Block');
    can('create', 'BlockFromRoute', {
      createRoles: { $in: user.roles },
    });
    can('update', 'Block', {
      'owner.organizationId': { $in: user.memberOrgs },
    });
  }
  if (user?.roles?.includes('docowner')) {
    can('read', 'ProducerTools');
    can('own', 'Block');
  }
  if (user?.roles?.includes('category_editor')) {
    can('read', 'ProducerTools');
    can('own', 'Block');
    can('read', 'MyDocumentsToPublish');
    can('publish', 'Block', {
      'category.editors': user.id,
    });
  }
  if (user?.roles?.includes('api_editor')) {
    can('update', 'ApiConnection');
  }
  if (user?.roles?.includes('tester')) {
    can('create', 'Bug');
  }
  return build();
}

export const useStoreAbility = () => useAbility<StoreAbility>();
export const useSubject = <T extends Record<PropertyKey, unknown>>(
  subjectType: Subject,
  subjectInstance: T,
): ForcedSubject<Subject> => {
  return subject<Subject, T>(subjectType, subjectInstance);
};
