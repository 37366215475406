<script setup lang="ts">
import type { Guid, OrganizationInfo, PartialSome, Tag } from '@/api/types';
import BlockListItem from './BlockListItem.vue';
import SearchBar from '../components/SearchBar.vue';
import Checkbox from '@/components/forms/InputCheckbox.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { computed, ref, toRef, watch, watchEffect } from 'vue';
import { defaultBlocksRouteName } from '@/utilities/routeUtils';
import { throwOnError } from '@/api/composable';
import { useRoute, type LocationQueryValue } from 'vue-router';
import Dropdown, {
  toDropdownItems,
} from '@/components/forms/InputDropdown.vue';
import TagPicker from '@/components/forms/InputTagPicker.vue';
import { useI18n } from 'vue-i18n';
import {
  useBlocks,
  type GetBlocksOptions,
  type TranslatedBlock,
} from '@/api/blocks';
import { useAudienceTargeting } from '@/utilities/useAudienceTargeting';
import type { TranslatedCategory } from '@/api/types';
import type { cardTemplateIds } from '@/renderTemplates';
import {
  getConnectedFilterByListId,
  type ConnectionInfo,
  getConnectedListByFilterId,
} from './BlockListConnectionHelper';
import { DEFAULT_LOCALE } from '@/config';
import { useBlockListCategories } from '@/api/category';
import { useStoreAbility, useSubject } from '@/abilities';

const { t } = useI18n();
const { can } = useStoreAbility();

const props = withDefaults(
  defineProps<{
    routeName: string;
    title?: string;
    options: GetBlocksOptions;
    showBacklogFilter?: boolean;
    showEmptyCatFilter?: boolean;
    categoryIdFilter?: number[];
    hideOrgFilter?: boolean;
    hideSearch?: boolean;
    hideCategoryFilter?: boolean;
    hideTagFilter?: boolean;
    hideAllFilters?: boolean;
    maxItems?: number;
    cardTemplateId?: cardTemplateIds;
    configSearchText?: string;
    preSelectedTags?: PartialSome<Tag, 'text'>[];
    excludeTags?: PartialSome<Tag, 'text'>[];
    preSelectedOrgIds?: Guid[];
    connection?: ConnectionInfo;
  }>(),
  {
    routeName: defaultBlocksRouteName,
    title: undefined,
    showBacklogFilter: false,
    categoryIdFilter: undefined,
    maxItems: undefined,
    cardTemplateId: undefined,
    configSearchText: undefined,
    preSelectedTags: undefined,
    excludeTags: undefined,
    preSelectedOrgIds: undefined,
    connection: undefined,
  },
);

const emit = defineEmits<{
  (e: 'loaded', value: NonNullable<typeof blocks.value>): void;
}>();

function setBlocks(externalBlocks: TranslatedBlock[]) {
  blocks.value = externalBlocks;
}
function updateFilters(
  backlog: typeof showBacklog.value,
  categories: typeof selectedCategories.value,
  owners: typeof selectedOwners.value,
  tags: typeof selectedTags.value,
) {
  showBacklog.value = backlog;
  selectedCategories.value = categories;
  selectedOwners.value = owners;
  selectedTags.value = tags;
}

watch(
  () => props.preSelectedTags,
  () => (selectedTags.value = props.preSelectedTags ?? []),
);
function setSearchText(text: string) {
  searchText.value = text;
}
defineExpose({ setBlocks, updateFilters, setSearchText });

const isApproval = computed(() => props.options.mode === 'approval');

const showBacklog = ref<'include' | 'hide'>(
  props.showBacklogFilter ? 'hide' : 'include',
);
const showOnlyEmptyCats = ref(false);

const { result: blocks, loaded } =
  props.connection?.role !== 'filter'
    ? throwOnError(useBlocks(props.options))
    : { result: ref<TranslatedBlock[] | null>(null), loaded: ref(false) };
watch(loaded, () => {
  if (!loaded) return;
  emit('loaded', blocks.value ?? []);
  if (!blocks.value) return;
  if (props.connection?.role !== 'list') return;
  getConnectedFilterByListId(props.connection)?.setBlocks(blocks.value);
});
const audienceTargetedBlocks = useAudienceTargeting(
  blocks,
  props.options.mode === 'adminview' || props.options.mode === 'approval',
);

const categories = useBlockListCategories(toRef(props, 'categoryIdFilter'));

const selectedCategories = ref<TranslatedCategory[]>([]);
const toggleCategory = (category: TranslatedCategory) => {
  selectedCategories.value = selectedCategories.value.find(
    (i) => i.categoryId == category.categoryId,
  )
    ? selectedCategories.value.filter(
        (c) => c.categoryId !== category.categoryId,
      )
    : [...selectedCategories.value, category];
  filterCategories(selectedCategories.value);
};

const route = useRoute();
function getQueryIdParam(key: string): number[] | undefined {
  return !route.query[key]
    ? undefined
    : (Array.isArray(route.query[key])
        ? (route.query[key] as LocationQueryValue[])
        : [route.query[key] as LocationQueryValue]
      ).map((q) => parseInt(q ?? ''));
}

const queryCategories = getQueryIdParam('categories');
watchEffect(() => {
  showBacklog.value = props.showBacklogFilter ? 'hide' : 'include'; // Hide by default if the filter is visible
  let preselectedCategories: TranslatedCategory[];
  if (queryCategories) {
    preselectedCategories = categories.value.filter((c) =>
      queryCategories.includes(c.categoryId),
    );
  } else if (props.categoryIdFilter === undefined) {
    preselectedCategories = [];
  } else {
    preselectedCategories = categories.value;
  }
  selectedCategories.value = preselectedCategories;
});

const searchText = ref(
  (route.query['q'] as string) ?? props.configSearchText ?? '',
);

const filterCategories = (categories: TranslatedCategory[]) => {
  selectedCategories.value = categories;
};

const matchesSearch = (block: TranslatedBlock) => {
  const searchLower = searchText.value.toLowerCase();
  if (!block.content) {
    console.error('Block has no content, this should not happen', { block });
  }
  return (
    block.name.toLowerCase().includes(searchLower) ||
    block.description.toLowerCase().includes(searchLower) ||
    block.category?.name.toLowerCase().includes(searchLower) ||
    JSON.stringify(block.content)?.toLowerCase().includes(searchLower) ||
    JSON.stringify(block.tags)?.toLowerCase().includes(searchLower)
  );
};
const isInSelectedCategory = (block: TranslatedBlock) => {
  if (showOnlyEmptyCats.value) return !block.category;
  if (selectedCategories.value.length == 0) {
    return categories.value.find(
      (i) => i.categoryId == block.category?.categoryId,
    );
  } else {
    return selectedCategories.value.find(
      (i) => i.categoryId == block.category?.categoryId,
    );
  }
};

const backlogFilter = (block: TranslatedBlock) => {
  if (showBacklog.value == 'hide' && block.isBacklog) return false;
  return true;
};

const ownerOptions = computed(() => {
  if (!blocks.value || !categories.value) return [];
  const orgs = blocks.value
    .filter((b) =>
      categories.value.find((c) => c.categoryId === b.category?.categoryId),
    )
    .map((b) => b.ownerOrg)
    .filter((o) => o) as OrganizationInfo[];
  return toDropdownItems(
    orgs.filter(
      (value, index) =>
        orgs.findIndex((o) => o.organizationId === value.organizationId) ===
        index,
    ),
    (item) => [item.organizationId, item.name, item],
  );
});

const selectedOwners = ref<OrganizationInfo[]>([]);
watch([ownerOptions, () => props.preSelectedOrgIds], () => {
  selectedOwners.value = props.preSelectedOrgIds
    ? ownerOptions.value
        .map((o) => o.value)
        .filter((o) => props.preSelectedOrgIds?.includes(o.organizationId))
    : [];
});
const ownerFilter = (block: TranslatedBlock) => {
  if (selectedOwners.value.length === 0) return true;
  if (
    selectedOwners.value.find(
      (o) => o.organizationId === block.ownerOrg?.organizationId,
    )
  ) {
    return true;
  }
  return false;
};

const queryTags = getQueryIdParam('tags')?.map(
  (id): Tag => ({ id, text: { [DEFAULT_LOCALE]: '' } }),
);
const selectedTags = ref<PartialSome<Tag, 'text'>[]>(
  props.preSelectedTags ?? queryTags ?? [],
);
const tagFilter = (block: TranslatedBlock) => {
  if (
    props.excludeTags?.find((t) => block.tags?.find((bt) => bt.id === t.id))
  ) {
    return false;
  }
  if (selectedTags.value.length === 0) return true;
  return selectedTags.value.find((t) =>
    block.tags?.find((bt) => bt.id === t.id),
  );
};

const filteredBlocks = computed(() => {
  return (
    audienceTargetedBlocks.value
      ?.filter(
        (b) =>
          backlogFilter(b) &&
          isInSelectedCategory(b) &&
          matchesSearch(b) &&
          ownerFilter(b) &&
          tagFilter(b),
      )
      .slice(0, props.maxItems) ?? []
  );
});
const missingConnection = computed(
  () =>
    props.connection?.role === 'filter' &&
    !getConnectedListByFilterId(props.connection),
);
watch([showBacklog, selectedCategories, selectedOwners, selectedTags], () => {
  if (props.connection?.role !== 'filter') return;
  getConnectedListByFilterId(props.connection)?.updateFilters(
    showBacklog.value,
    selectedCategories.value,
    selectedOwners.value,
    selectedTags.value,
  );
});

const hideAllFiltersExceptSearch = computed(
  () => props.hideAllFilters || !!getConnectedFilterByListId(props.connection),
);
const showNewButton = computed(
  () =>
    route.meta.isDocsListRoute &&
    can(
      'create',
      useSubject('BlockFromRoute', {
        createRoles:
          categories.value
            ?.map((c) => c.createRoles)
            .flat()
            .filter((r) => r !== undefined) ?? [],
      }),
    ),
);
</script>

<template>
  <div class="flex flex-col">
    <p v-if="title" class="text-p1 lg:text-center">
      {{ title }}
    </p>
    <p v-if="missingConnection" class="alert alert-warning text-center">
      {{ t('blockList.missingConnection') }}
    </p>

    <div
      class="hide-empty flex flex-col gap-4 lg:mx-auto lg:w-11/12 lg:max-w-4xl lg:flex-row"
    >
      <SearchBar
        v-if="!hideSearch && !hideAllFilters && connection?.role !== 'filter'"
        class="text-p2 my-2 w-full"
        :initial-value="searchText"
        @change="(v: string) => (searchText = v)"
      />
      <Dropdown
        v-if="!hideOrgFilter && !hideAllFiltersExceptSearch"
        v-model="selectedOwners"
        :options="ownerOptions"
        :getkey="(val) => val?.organizationId ?? 'empty'"
        :multiple="{ emptyLabel: 'blockList.orgFilter' }"
        class="w-full px-1 lg:max-w-xs"
        :buttonclass="[
          'w-full mt-2 px-2 py-2 pb-2  [&>*]:pb-2',
          'border rounded',
          'bg-gnist-white',
        ]"
      />
    </div>

    <div
      class="hide-empty mx-auto flex w-full flex-col flex-wrap justify-between gap-4 px-1 lg:max-w-4xl lg:flex-row lg:items-end"
    >
      <Checkbox
        v-if="showEmptyCatFilter && !hideAllFiltersExceptSearch"
        v-model="showOnlyEmptyCats"
        label="blockList.showOnlyEmptyCats"
        direction="horizontal"
        mode="toggle"
        class="decoration mx-0.5 mt-2 flex basis-full items-center whitespace-nowrap lg:mt-0"
      />
      <TagPicker
        v-if="!hideTagFilter && !hideAllFiltersExceptSearch"
        v-model="selectedTags"
        label="blockList.tags"
        suggestedlabel="blockList.suggestedTags"
        :taglistclass="['max-h-8 overflow-y-auto']"
        class="-mb-2 w-full grow"
        select-only
      />
      <template
        v-if="
          categories?.length != 1 &&
          !showOnlyEmptyCats &&
          !hideCategoryFilter &&
          !hideAllFiltersExceptSearch
        "
      >
        <div class="hidden lg:contents">
          <Checkbox
            v-for="c in categories"
            :key="c.categoryId"
            :model-value="
              !!selectedCategories.find((i) => i.categoryId == c.categoryId)
            "
            :label="c.name"
            checkbox-class="my-0.5"
            direction="horizontal"
            @update:model-value="toggleCategory(c)"
          >
            <span
              class="decoration whitespace-nowrap pb-0 underline decoration-4 underline-offset-4"
              :class="`decoration-gnist-${c.color}`"
            >
              {{ c.name }}
            </span>
          </Checkbox>
        </div>

        <Dropdown
          v-model="selectedCategories"
          :options="{
            values: categories,
            getOption: (c) => [c.categoryId.toString(), c.name, c],
          }"
          :multiple="{ emptyLabel: 'blockList.categoryFilter' }"
          :getkey="(c) => c?.categoryId"
          class="max-w-full lg:hidden"
          :buttonclass="[
            'w-full mt-2 px-2 py-2 pb-2  [&>*]:pb-2',
            'border rounded',
            'bg-gnist-white',
          ]"
        >
          <template #button="{ selectedItems }">
            <template v-for="(c, index) of selectedItems" :key="c">
              <span
                class="decoration items-center whitespace-nowrap underline decoration-4 underline-offset-4"
                :class="[`decoration-gnist-${c?.color}`]"
              >
                {{ c?.name }}
              </span>
              {{ index + 1 < selectedItems.length ? ',' : '' }}&nbsp;
            </template>
          </template>
          <template #option="{ item }">
            <span
              class="decoration flex items-center whitespace-nowrap underline decoration-4 underline-offset-4"
              :class="[`decoration-gnist-${item.value.color}`]"
            >
              {{ item.text }}
            </span>
          </template>
        </Dropdown>
        <div class="grow">&NonBreakingSpace;</div>
      </template>
      <Checkbox
        v-if="showBacklogFilter && !hideAllFiltersExceptSearch"
        :model-value="showBacklog == 'include'"
        label="blockList.showBacklog"
        direction="horizontal"
        mode="toggle"
        @update:model-value="
          (checked) => (showBacklog = checked ? 'include' : 'hide')
        "
      />
    </div>

    <div v-if="showNewButton" class="my-8 flex justify-end pr-8">
      <RouterLink
        class="gnist-button gnist-button-primary"
        :to="{ name: 'new_block' }"
        data-cy-id="NewDocumentButton"
      >
        {{ t('admin.blockProduction.newBlock') }}
      </RouterLink>
    </div>
    <template v-if="connection?.role !== 'filter'">
      <div
        class="flex flex-col items-stretch gap-8 lg:flex-row lg:flex-wrap"
        :class="{
          'mt-12':
            !isApproval &&
            !(hideAllFiltersExceptSearch && hideSearch) &&
            !showNewButton,
          'lg:justify-center': !(isApproval && (blocks?.length ?? 0) === 0),
        }"
        data-cy-id="SearchResults"
      >
        <LoadingSpinner v-if="blocks === null" class="h-16 w-16" />
        <div v-else-if="isApproval && blocks.length === 0">
          {{ t('admin.management.nothingToPublish') }}
        </div>
        <BlockListItem
          v-for="blockItem in filteredBlocks"
          :key="blockItem.blockId"
          :block-item="blockItem"
          :route-name="routeName"
          :card-template-id="props.cardTemplateId"
        />
      </div>
    </template>
  </div>
</template>

<style scoped>
.RouteDisclaimer :deep(a) {
  @apply underline;
}
</style>
