<script
  setup
  lang="ts"
  generic="
    T extends NonNullable<unknown>,
    SortField extends KeyOfRecursive<T, true>
  "
>
import type {
  ArrayItem,
  FgColors,
  KeyOfRecursive,
  RecurChildType,
  VueClass,
} from '@/api/types';
import {
  useSort,
  type Comparator,
  type SortDirection,
} from '@/utilities/order';
import { computed, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import LoadingSpinner from './LoadingSpinner.vue';

const props = defineProps<{
  rows: T[] | null | undefined;
  i18nKey: string;
  showSpinner?: boolean;
  getKey: (item: T) => string | number;
  sortFields?: Readonly<SortField[]> | SortField;
  defaultSortfield?: SortField;
  defaultSortDirection?: SortDirection;
  comparers?: {
    [key in SortField]?: Comparator<
      RecurChildType<T, key> extends Array<unknown> | undefined
        ? ArrayItem<RecurChildType<T, key>>
        : RecurChildType<T, key>
    >;
  };
  skipSortOnContentChange?: boolean;
  groupColumnHeader?: boolean;
  columns: (SortField | `${SortField}.title` | null)[];
  /** Use if you have complex logic for deciding the column headers */
  getColumnHeader?: (column: SortField) => string;
  size?: 'table-xs' | 'table-sm' | 'table-md' | 'table-lg';
  columnHeaderClass?: VueClass;
  rowClass?: VueClass;
  /** Defaults to 'both' */
  pin?: 'rows' | 'cols' | 'both' | 'none';
  noZebra?: boolean;
  textColor?: FgColors;
  headerTextColor?: FgColors;
  noHeader?: boolean;
}>();

const emit = defineEmits<{
  (e: 'click:row', row: T, ev: MouseEvent): void;
}>();

const slots = defineSlots<{
  /** Should return the *content* (not `<th></th>`) for the column header (the first column) if you want a column header.
   * If you don't want a column header, do not use this slot. **Important:** the column header will stick if you scroll horizontally in mobile mode, so it is recommended to use this slot. */
  columnHeader?: (props: {
    item: ArrayItem<(typeof sortedValues)['value']>;
  }) => Element;
  /** Should return the Table Data cells for all column except the column header (if you have one), inlcuding `<td></td>` for each column. */
  columns: (props: {
    item: ArrayItem<(typeof sortedValues)['value']>;
    rowIndex: number;
    items: T[];
  }) => HTMLTableCellElement;
}>();

const { t } = useI18n();

const _sortFields = computed((): SortField[] | undefined =>
  props.sortFields
    ? Array.isArray(props.sortFields)
      ? props.sortFields
      : [props.sortFields]
    : undefined,
);

const { sortOnField, getHeaderClasses, sortedValues, isGrouped } = useSort(
  toRef(props, 'rows'),
  _sortFields.value,
  props.defaultSortfield ?? _sortFields.value?.[0],
  props.defaultSortDirection,
  props.comparers,
  props.skipSortOnContentChange,
  (field) => props.groupColumnHeader && field === props.columns[0],
);

const columnList = computed(
  (): ({ key: SortField; label: string } | undefined)[] => {
    return props.columns.map((col) => {
      const key = col?.replace('.title', '') as SortField;
      return col
        ? {
            key,
            label: props.getColumnHeader?.(key) ?? t(`${props.i18nKey}.${col}`),
          }
        : undefined;
    });
  },
);
</script>

<template>
  <div class="GnistTable">
    <table
      class="table overflow-auto"
      :class="[
        size,
        {
          'text-gnist-black': !textColor,
          [`text-gnist-${textColor}`]: textColor,
          'table-pin-rows':
            pin === 'rows' || pin === 'both' || pin === undefined,
          'table-pin-cols':
            pin === 'cols' || pin === 'both' || pin === undefined,
        },
      ]"
    >
      <thead
        v-if="!noHeader"
        class="z-20"
        :class="{
          'text-gnist-black': !headerTextColor,
          [`text-gnist-${headerTextColor}`]: headerTextColor,
        }"
      >
        <tr>
          <template v-for="(column, index) in columnList" :key="column">
            <th
              v-if="index === 0"
              class="z-10"
              :class="[
                column && getHeaderClasses(column.key),
                { 'w-0': !column },
              ]"
              @click="column && sortOnField(column.key)"
            >
              {{ column?.label ?? '' }}
            </th>
            <td
              v-else
              :class="[
                column && getHeaderClasses(column.key),
                { 'w-0': !column },
              ]"
              @click="column && sortOnField(column.key)"
            >
              {{ column?.label ?? '' }}
            </td>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!sortedValues">
          <td
            :colspan="columns.length"
            class="w-full items-center p-8 text-center"
          >
            <div class="flex w-full flex-col items-center">
              <LoadingSpinner v-if="showSpinner" class="h-16 w-16" />
            </div>
          </td>
        </tr>
        <template v-else>
          <tr
            v-for="(row, index) in sortedValues"
            :key="getKey(row)"
            class="bg-gnist-white hover:bg-gnist-blue-light-light"
            :class="[rowClass, { 'even:bg-gnist-gray-light-light': !noZebra }]"
            @click="(ev) => emit('click:row', row, ev)"
          >
            <th
              v-if="slots.columnHeader && (row.isGroupHeader || !isGrouped)"
              :rowspan="isGrouped ? row.groupSize : undefined"
              :scope="isGrouped ? 'rowgroup' : undefined"
              class="ColumnHeader z-10"
              :class="[
                columnHeaderClass,
                { 'bg-gnist-white align-top': isGrouped },
              ]"
            >
              <slot name="columnHeader" :item="row" />
            </th>
            <slot
              name="columns"
              :item="row"
              :row-index="index"
              :items="sortedValues"
            />
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<style>
.GnistTable {
  @apply max-h-[90vh] w-full max-w-full;
  @apply overflow-x-auto;
  @apply border-b;
}
.GnistTable:not([class*='max-h-']) {
  @apply sm:max-h-[unset];
  @apply sm:overflow-x-visible;
}
.ColumnHeader {
  min-width: clamp(30vw, 4rem, 40vw);
  @apply md:min-w-min;
  max-width: 40vw;
}
.ColumnHeader:not([class*='bg-']) {
  background-color: inherit;
}
.GnistTable .table-lg :where(thead, tfoot) {
  font-size: 1rem;
}
</style>
