<script
  setup
  lang="ts"
  generic="T extends Translated<BlockLocaleValue> | string | string[] | number"
>
import { useI18n } from 'vue-i18n';
import InputLabel, { type IInputLabelProps } from './InputLabel.vue';
import { computed, ref, watch } from 'vue';
import type { BlockLocaleValue, Translated, VueClass } from '@/api/types';

const props = defineProps<
  IInputLabelProps & {
    modelValue?: T | null;
    parseval?: (val: unknown) => T;
    validate?: ((val: T) => boolean) | RegExp;
    multiline?: boolean;
    rows?: number;
    min?: number;
    max?: number;
    disabled?: boolean;
    showValidationMessage?: boolean;
    inputClass?: VueClass;
    type?: 'text' | 'number' | 'email' | 'password' | 'tel';
    noChrome?: boolean;
  }
>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: T): void;
  (e: 'update:valid', valid: boolean): void;
  (e: 'focus'): void;
  (e: 'blur'): void;
}>();
const { t } = useI18n();
const value = computed(() =>
  props.modelValue === null || props.modelValue === undefined
    ? ''
    : typeof props.modelValue === 'string' ||
        (Array.isArray(props.modelValue) &&
          !props.modelValue.some((i) => typeof i !== 'string'))
      ? (props.modelValue as string | string[])
      : JSON.stringify(props.modelValue),
);

function onChange(val: string) {
  let parsedVal = props.parseval?.(val);
  if (props.type === 'number') parsedVal ??= parseInt(val) as T;
  parsedVal ??= val as T;
  emit('update:modelValue', parsedVal);
  if (typeof props.validate === 'function') {
    emit('update:valid', props.validate(parsedVal));
  }
}

const length = computed(() => {
  if (Array.isArray(value.value)) return value.value.length;
  return value.value?.length ?? 0;
});
const singleLineRef = ref<HTMLInputElement | null>(null);
const multiLineRef = ref<HTMLTextAreaElement | null>(null);
watch(
  () => value.value,
  () => {
    const activeRef = props.multiline
      ? multiLineRef.value
      : singleLineRef.value;
    if (props.showValidationMessage) activeRef?.reportValidity();
    emit('update:valid', activeRef?.checkValidity() ?? false);
  },
);
</script>
<template>
  <label :for="forName" class="flex flex-col whitespace-nowrap">
    <InputLabel
      v-bind="props"
      :label-end="!!maxlength ? `${length} / ${maxlength}` : undefined"
    />
    <input
      v-if="!multiline"
      :id="forName"
      ref="singleLineRef"
      :value="value"
      :type="type ?? 'text'"
      :name="forName"
      :title="t(label ?? '')"
      class="my-2 grow border p-2"
      :class="[
        inputClass,
        { 'bg-transparent': noChrome, 'border-none': noChrome },
      ]"
      :required="required"
      :minlength="minlength"
      :maxlength="maxlength"
      :min="min"
      :max="max"
      :placeholder="t(placeholder ?? '')"
      :disabled="disabled"
      :pattern="
        typeof validate === 'function'
          ? undefined
          : validate?.toString().replace(/\/(.*)\/\S*/, '$1')
      "
      :aria-describedby="forName ? `desc_${forName}` : undefined"
      :data-cy-id="`InputText_${forName ?? label?.split('.').slice(-1)}`"
      @input="(ev) => onChange((ev.target as HTMLInputElement).value)"
      @focus="
        if (showValidationMessage) singleLineRef?.reportValidity();
        emit('focus');
      "
      @blur="emit('blur')"
    />
    <textarea
      v-else
      :id="forName"
      ref="multiLineRef"
      :value="value"
      :name="forName"
      :title="t(label ?? '')"
      :rows="rows"
      class="my-2 w-full border p-2"
      :class="[
        inputClass,
        { 'bg-transparent': noChrome, 'border-none': noChrome },
      ]"
      :required="required"
      :disabled="disabled"
      :minlength="minlength"
      :maxlength="maxlength"
      :placeholder="t(placeholder ?? '')"
      :pattern="
        typeof validate === 'function'
          ? undefined
          : validate?.toString().replace(/\/(.*)\/\S*/, '$1')
      "
      :aria-describedby="forName ? `desc_${forName}` : undefined"
      :data-cy-id="`InputText_${forName ?? label?.split('.').slice(-1)}`"
      @input="(ev) => onChange((ev.target as HTMLTextAreaElement).value)"
      @focus="
        if (showValidationMessage) multiLineRef?.reportValidity();
        emit('focus');
      "
      @blur="emit('blur')"
    />
  </label>
</template>

<style scoped>
input[disabled],
textarea[disabled] {
  @apply bg-gnist-gray-light;
}
</style>
