<script setup lang="ts">
import Picker from '@/components/forms/InputPicker.vue';
import type { Guid, Link, Translated } from '@/api/types';
import { useNestedMV } from '@/utilities/useInternalState';
import { computed, ref, watch, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import {
  getBlocksRouteLink,
  getDocumentRouteName,
  removeRouteIdentifier,
} from '@/utilities/routeUtils';
import { hardcodedStaticRoutes } from '@/router/routeUtils';
import { useRouteItems } from '@/api/menu';
import { translateStringOrLocale } from '@/i18n';
import InputBlockPicker from './forms/InputBlockPicker.vue';

const { t } = useI18n();

const props = defineProps<{
  modelValue: Pick<Translated<Link>, 'url'> &
    Partial<Pick<Translated<Link>, 'text' | 'alt'>>;
  hideText?: boolean;
  hideDescription?: boolean;
  dataCyId: string;
  showValidationMessage?: boolean;
  required?: boolean;
  /** Used for connecting label and input */
  forName: string;
  canTargetBlocks?: boolean;
  canTargetHardcoded?: boolean;
  canTargetRoutes?: boolean;
  selectedType: 'block' | 'hardcoded' | 'route' | 'link' | undefined;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: typeof props.modelValue): void;
  (e: 'update:valid', valid: boolean): void;
  (e: 'update:selectedType', type: typeof props.selectedType): void;
}>();

const value = useNestedMV(props, (val) => emit('update:modelValue', val));
const urlRef = ref<HTMLInputElement | null>(null);
watch(
  () => value.value.url,
  () => emit('update:valid', urlRef.value?.reportValidity() ?? false),
);
const router = useRouter();

const selectedBlockId = ref<Guid | undefined>();

const hardcodedRouteOptions = computed(() =>
  hardcodedStaticRoutes.map((r) => ({
    ...r,
    name: r.name?.toString() ?? '',
    text: r.sourceTitle
      ? `${t(r.sourceTitle)} ["${r.fullPath?.replace(/\/:.*?\?/g, '')}"]`
      : r.fullPath?.replace(/\/:.*?\?/g, '') ?? r.path,
  })),
);
type HardcodedRoute = (typeof hardcodedRouteOptions.value)[number];
const selectedHardcoded = ref<HardcodedRoute | undefined>();
async function searchHardcoded(query: string): Promise<HardcodedRoute[]> {
  if (!props.canTargetHardcoded) return [];
  const itemLimit = 25;
  const matches =
    query.trim() == ''
      ? hardcodedRouteOptions.value
      : hardcodedRouteOptions.value.filter((b) =>
          b.text.toLowerCase().includes(query),
        );
  return matches.slice(0, itemLimit);
}

const _routeItems: Ref<ReturnType<typeof useRouteItems>['result']['value']> =
  props.canTargetRoutes ? useRouteItems().result : ref(null);
type TranslatedRouteItem = Translated<
  NonNullable<typeof _routeItems.value>[number]
>;
const routeItems = ref<TranslatedRouteItem[]>();
watch(_routeItems, () => {
  routeItems.value = _routeItems.value?.map(
    (r): TranslatedRouteItem => ({
      ...r,
      text: translateStringOrLocale(r.text).value,
    }),
  );
});
const selectedRouteItem = ref<TranslatedRouteItem>();
async function searchRouteItems(query: string): Promise<TranslatedRouteItem[]> {
  if (!routeItems.value) return [];
  const itemLimit = 25;
  const matches =
    query.trim() == ''
      ? routeItems.value
      : routeItems.value.filter((b) => b.text.toLowerCase().includes(query));
  return matches.slice(0, itemLimit);
}
const blocksLoaded = ref(false);
watch(
  [blocksLoaded, routeItems],
  () => {
    if (!value.value.url) {
      emit('update:selectedType', 'link');
      return;
    }
    if (props.canTargetBlocks && !blocksLoaded.value) return;
    if (props.canTargetRoutes && !routeItems.value) return;
    // Attempt to set selected value based on the stored url
    const routeMatch = router.resolve(value.value.url);
    if (props.canTargetBlocks) {
      selectedBlockId.value = routeMatch.params.blockId as Guid;
    }
    const savedRouteName = removeRouteIdentifier(
      routeMatch.name?.toString() ?? '',
    );
    if (props.canTargetHardcoded) {
      selectedHardcoded.value = hardcodedRouteOptions.value.find(
        (r) => r.name === savedRouteName,
      );
    }
    if (props.canTargetRoutes) {
      selectedRouteItem.value = routeItems.value?.find(
        (b) => b.routeName === savedRouteName,
      );
    }
    // Define what is actually selected (in case there are multiple matches), and set selectedType
    if (selectedRouteItem.value) {
      emit('update:selectedType', 'route');
    } else if (selectedHardcoded.value) {
      emit('update:selectedType', 'hardcoded');
    } else if (selectedBlockId.value) {
      emit('update:selectedType', 'block');
    } else {
      emit('update:selectedType', 'link');
    }
  },
  { immediate: true },
);

watch(
  () => props.selectedType,
  (_, oldVal) => {
    if (oldVal === undefined) return; // Initial setting of value
    // Clear all stored values if type is changed
    selectedBlockId.value = undefined;
    selectedHardcoded.value = undefined;
    selectedRouteItem.value = undefined;
    value.value.url = '';
  },
);
</script>

<template>
  <div :data-cy-id="dataCyId">
    <Picker
      v-if="selectedType === 'hardcoded'"
      :model-value="selectedHardcoded"
      suggestedlabel="block.schema.linkRouteSuggestion"
      :getkey="(val) => val?.name?.toString() ?? ''"
      :gettext="(val) => val?.text ?? ''"
      :taglistclass="['w-full grow [&>*]:grow [&>*]:justify-between']"
      :filter="searchHardcoded"
      :max-items="1"
      show-empty-suggestions
      class="row-span-2 md:mr-4"
      @update:model-value="
        (val) => {
          selectedHardcoded = val;
          value.url = val?.name
            ? router.resolve({ name: val.name }).fullPath
            : '';
          if (hideText || !value.text) {
            value.text = val?.text;
          }
        }
      "
    />
    <Picker
      v-if="selectedType === 'route'"
      :model-value="selectedRouteItem"
      suggestedlabel="block.schema.linkRouteItemSuggestion"
      :getkey="(val) => val?.id.toString() ?? ''"
      :gettext="(val) => val?.text ?? ''"
      :taglistclass="['w-full grow [&>*]:grow [&>*]:justify-between']"
      :filter="searchRouteItems"
      :max-items="1"
      show-empty-suggestions
      class="row-span-2 md:mr-4"
      @update:model-value="
        (val) => {
          selectedRouteItem = val;
          value.url = val?.routeName
            ? router.resolve({
                name: getDocumentRouteName(val.routeName, true),
              }).fullPath
            : '';
          if (hideText || !value.text) {
            value.text = val?.text;
          }
        }
      "
    />

    <input
      v-else-if="selectedType === 'link'"
      :id="forName"
      ref="urlRef"
      v-model="value.url"
      type="url"
      class="w-full border p-2"
      :placeholder="t('block.schema.linkUrlPlaceholder')"
      :required="required"
      data-cy-id="InputLinkUrl"
      @focus="if (showValidationMessage) urlRef?.reportValidity();"
    />
    <InputBlockPicker
      v-if="canTargetBlocks"
      v-show="selectedType === 'block'"
      :model-value="selectedBlockId"
      suggestedlabel="block.schema.linkBlockSuggestion"
      @loaded="blocksLoaded = true"
      @changed="
        (val) => {
          if (hideText || !value.text) {
            value.text = val?.name;
          }
          value.alt = val?.description;
        }
      "
      @update:model-value="
        (val) => {
          selectedBlockId = val;
          value.url = val
            ? router.resolve(getBlocksRouteLink(val)).fullPath
            : '';
        }
      "
    />
    <template v-if="!hideText">
      <label class="mt-6 block">{{ t('block.schema.linkTextTitle') }}</label>
      <input
        v-model="value.text"
        class="w-full border p-2"
        :placeholder="t('block.schema.linkTextPlaceholder')"
        data-cy-id="InputLinkText"
      />
    </template>
    <template v-if="!hideDescription">
      <label class="mt-6 block">{{
        t('block.schema.linkDescriptionTitle')
      }}</label>
      <input
        v-model="value.alt"
        class="w-full border p-2"
        :placeholder="t('block.schema.linkDescriptionPlaceholder')"
        data-cy-id="InputLinkDescription"
      />
    </template>
  </div>
</template>
